import { isFunction } from 'lodash';
import state from '../store/state';

const { gtag } = window;

export const gtagEvent = (name, options) => {
  if (isFunction(gtag)) {
    try {
      const event = {
        environment: process.env.NODE_ENV,
        channel: state.platform.channel,
        company: state.platform.companyName,
        ...options,
      };

      gtag('event', name, event);
    } catch (error) {
      // eslint-disable-next-line
      console.warn('[Roulette]:GTagEventError', error);
    }
  }
};

export const gtagPageView = (path) => {
  if (isFunction(gtag)) {
    try {
      gtag('set', 'page_path', path);
      gtag('event', 'page_view');
    } catch (error) {
      // eslint-disable-next-line
      console.warn('[Roulette]:GTagPageViewError', error);
    }
  }
};
