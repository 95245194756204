import {
  assign,
  each,
  has,
  includes,
  isNil,
  isString,
  keys,
  map,
  some,
  toLower,
  toUpper,
  upperFirst,
} from 'lodash';
import taxService from '@nsftx/seven-gravity-tax-service';
import { formatter, localStorage, webViewIntegration } from '@/utility';

export default {
  authStrategies: (state) => state.authStrategies,
  channelSettings: (state, getters) => {
    const channel = getters.platformChannel.toLowerCase();
    const settings = getters.config.gateway.settings[channel]
      ? getters.config.gateway.settings[channel] : getters.config.gateway.settings.shop;
    const { wheelBets } = getters;
    const { wheelTickets } = getters;
    const payinTaxPolicyConfig = taxService.policies[wheelTickets.ticketPayinTaxPolicy] || null;
    const payoutTaxPolicyConfig = taxService.policies[wheelTickets.ticketPayoutTaxPolicy] || null;
    const { bets } = getters.config.wheel.definition;
    const translatedShortcuts = {};
    const sortedTranslatedShortcuts = {};
    const betsConfiguration = keys(bets);

    sortedTranslatedShortcuts['+'] = {
      name: state.translations.focusBetInput,
      shortcut: '+',
      active: true,
    };

    sortedTranslatedShortcuts['/'] = {
      name: state.translations.clearBetslip,
      shortcut: '/',
      active: true,
    };

    sortedTranslatedShortcuts['CTRL+M'] = {
      name: state.translations.focusFirstBet,
      shortcut: 'CTRL+M',
      active: true,
    };

    each(betsConfiguration, (betName) => {
      // eslint-disable-next-line;
      const bet = bets[betName];
      const hasDescription = ['basket'];
      if (bet.name === 'straight') return;
      const title = wheelBets[bet.name] ? wheelBets[bet.name].title : state.translations[bet.name];
      const shortcutKey = toUpper(bet.shortcut.name);
      const description = hasDescription.indexOf(bet.name) > -1 ? ` (${formatter.numberFormatter(bet.values)})` : '';
      const modifiedShortcut = {
        name: title,
        // eslint-disable-next-line
        shortcut: shortcutKey,
      };
      translatedShortcuts[shortcutKey] = assign({}, modifiedShortcut, {
        action: 'Standard',
        active: true,
        name: `${title}${description}`,
      });
    });

    keys(translatedShortcuts)
      .sort()
      .forEach((key) => {
        sortedTranslatedShortcuts[key] = translatedShortcuts[key];
      });

    // Set Q shortcut always on end
    // eslint-disable-next-line
    sortedTranslatedShortcuts['Q'] = {
      // eslint-disable-next-line
      name: state.translations['results'] || 'Results',
      shortcut: 'Q',
      active: true,
    };

    return {
      ticketConfig: settings.ticketConfig,
      shortcuts: sortedTranslatedShortcuts,
      tax: {
        payin: {
          hideTax: isNil(payinTaxPolicyConfig) || toLower(payinTaxPolicyConfig.payer) === 'company',
          policy: wheelTickets.ticketPayinTaxPolicy || '',
          value: has(taxService.policies, wheelTickets.ticketPayinTaxPolicy),
        },
        payout: {
          hideTax: isNil(payinTaxPolicyConfig) || toLower(payoutTaxPolicyConfig.payer) === 'company',
          policy: wheelTickets.ticketPayoutTaxPolicy || '',
          value: has(taxService.policies, wheelTickets.ticketPayoutTaxPolicy),
        },
      },
    };
  },
  betslip: (state) => state.betslip,
  config: (state) => state.config,
  theme: (state) => state.theme,
  palette: (state) => state.palette,
  isRoundInProgress: (state, getters) => {
    const { roundEvent } = getters;
    const roundEvents = ['countdown', 'bettingDisabled'];
    return roundEvent && roundEvents.indexOf(roundEvent.name) < 0;
  },
  isBettingDisabled: (state, getters) => {
    const { roundEvent } = getters;
    return roundEvent && roundEvent.name === 'bettingDisabled';
  },
  isTicketsResolvedEventSendable: (state, getters) => (getters.isWebChannel
    || getters.isMobileChannel),
  nextRound: (state, getters) => {
    if (getters.round) {
      return {
        displayId: getters.round.displayId + 1,
      };
    }

    return getters.round;
  },
  manualBalanceUpdate: (getters) => getters.isLightningIntegration, // todo: move to game-config
  nextRounds: (state) => state.nextRounds,
  directPayin: (state, getters) => (getters.isWebChannel || getters.isMobileChannel),
  platform: (state) => state.platform,
  platformChannel: (state) => state.platform.channel,
  channel: (state) => toLower(state.platform.channel),
  channelViewOverride: (state, getters) => (getters.channel && getters.channel !== toLower(getters.route.name) && !includes(toLower(getters.route.name), 'mobile') && getters.channel !== 'mobile'
    ? toLower(getters.route.name) : undefined),
  currency: (state) => (isString(state.platform.currency)
    ? state.platform.currency : state.user.currency),
  isTerminal: (state, getters) => (getters.channel === 'terminal'),
  isRetail: (state, getters) => (getters.channel === 'shop'),
  isWebChannel: (state, getters) => (getters.channel === 'web'),
  isMobileChannel: (state, getters) => getters.channel === 'mobile',
  isWebViewIntegrationActive: () => webViewIntegration.isActive(),
  hasWindowWebAppListener: () => webViewIntegration.isActive() && has(window, 'WebAppListener'),
  route: (state) => state.route,
  round: (state) => state.round,
  updateBet: (state) => state.updateBet,
  shortcuts: (state) => state.config.shortcuts,
  socketProvider: (state) => state.socketProvider,
  roundEvent: (state, getters) => (getters.round ? getters.round.event : null),
  roundEventDuration: (state, getters) => {
    if (getters.roundEvent && getters.wheel) {
      const wheelEvent = getters.wheel.definition.events[getters.roundEvent.name];
      return wheelEvent.duration;
    }

    return null;
  },
  roundEventStart: (state, getters) => (getters.roundEvent ? getters.roundEvent.createdAt : null),
  roundStatus: (state, getters) => (getters.round ? getters.round.status : null),
  ticket: (state) => state.ticket,
  ticketBets: (state) => state.ticket.bets,
  ticketStore: (state) => state.ticketStore,
  ticketStatuses: (state) => state.ticketStatuses,
  translation: (state) => (key) => state.translations[key],
  translations: (state) => state.translations,
  user: (state) => state.user,
  ui: (state) => state.ui,
  wheel: (state, getters) => getters.config.wheel,
  wheelType: (state, getters) => getters.config.wheel.type,
  wheelBets: (state, getters) => getters.wheel.definition.bets,
  wheelTickets: (state, getters) => getters.wheel.definition.tickets,
  wheelNumbers: (state, getters) => getters.config.wheel.definition.layout.wheel.numbers,
  wheelLayout: (state, getters) => getters.wheel.definition.layout,
  wheelPresets: (state, getters) => getters.config.wheel.definition.presets,
  insideBetsItems: (state) => state.insideBetsItems,
  outsideBetsItems: (state) => state.outsideBetsItems,
  inputErrorMessage: (state) => state.inputErrorMessage,
  rouletteResults: (state) => state.rouletteResults,
  ticketResult: (state) => state.ticketResult,
  allowedShortcutChars: (state) => state.allowedShortcutChars,
  multiStraightSeparator: (state, getters) => getters.allowedShortcutChars[0],
  betShortcuts: (state) => state.betShortcuts,
  totalBetStake: (state) => (state.totalBetStake ? state.totalBetStake : 0),
  displayBet: (state) => state.displayBet,
  balanceAmount: (state) => state.balance,
  balance: (state, getters) => {
    if (state.balance >= getters.totalPayment) {
      return true;
    }

    return false;
  },
  showLastTicketsTooltip: (state) => state.showLastTicketTooltip && localStorage.getItem('showLastTicketTooltip') !== 'false',
  showClearTableTooltip: (state) => state.showClearTableTooltip && localStorage.getItem('showClearTableTooltip') !== 'false',
  isMobile: (state) => state.platform.isMobile === 'true',
  isIntegration: (state) => state.platform.isIntegration,
  activeMessage: (state) => state.activeMessage,
  lastPlayedTicket: (state) => state.lastPlayedTicket,
  numberOfPlayedTickets: (state) => state.numberOfPlayedTickets,
  preloader: (state) => state.preloader,
  preloaderProgress: (state) => state.preloaderProgress,
  tpToken: (state) => state.tpToken || '',
  tpIntegration: (state) => state.tpIntegration,
  siteReferer: (state) => state.siteReferer,
  presets: (state) => state.presets,
  limits: (state) => state.limits,
  ticketPayment: (state) => state.ticketPayment,
  defaultPresets: (state) => state.defaultPresets,
  generatorCode: (state) => state.generatorCode,
  showBalance: (state) => state.showBalance === 'true',
  backButtonEnabled: (state) => state.backButtonEnabled === 'true',
  hasPlayerActiveTicket: (state) => some(state.lastPlayedTickets, (ticket) => ticket.status.name === 'open' || ticket.status.name === 'pending'),
  isUserAuthenticated: (state) => state.user.auth && state.user.auth.token,
  isLightningIntegration: (state) => state.isLightningIntegration,
  creationType: (state) => state.creationType,
  defaultChip: (state) => state.defaultChip,
  isRemoveButtonActive: (state) => state.isRemoveButtonActive,
  totalPayment: (state, getters) => {
    if (state.ticket.payment >= getters.limits.ticket.ticketMinPayment) {
      return state.ticket.payment;
    }
    return 0;
  },
  moneyFormat: (state) => state.moneyFormat,
  paymentPerBetStrategy: (state, getters) => getters.wheel?.definition?.paymentPerBetStrategy,
  numberDelimiter: (state) => state.platform?.cmsAppSettingsConfig?.numberDelimiter,
  ticketConfiguration: (state, getters) => {
    const config = {
      config: getters.betslip.tickets[0],
      creationType: getters.creationType,
      round: {
        id: getters.eventRound('id'),
        displayId: getters.eventRound('displayId'),
      },
    };
    return config;
  },
  headers: (state, getters) => {
    const headers = {
      'HTTP-X-SEVEN-CLUB-UUID': getters.platform.companyUuid,
      'HTTP-X-NAB-DP': upperFirst(getters.channel),
      'HTTP-X-NAB-PRODUCTNAME': 'Roulette',
      'HTTP-X-NAB-PRODUCTINSTANCE-ID': 'Roulette',
      'SEVEN-LOCALE': getters.platform.companyLanguage,
    };
    if (getters.tpIntegration) {
      headers['SEVEN-TP-TOKEN'] = getters.tpToken;
    }

    return headers;
  },
  metadata: (state, getters) => (uuid) => {
    const metadata = {
      cpvUuid: getters.platform.productId,
      deliveryPlatform: upperFirst(getters.channel),
      paymentMethod: 'VirtualMoney',
      product: 'Roulette',
      requestUuid: uuid,
      sources: [{
        firstName: getters.user.firstName,
        lastName: getters.user.lastName,
        type: 'player',
        uuid: getters.user.id,
        username: getters.user.name,
      }],
    };

    return metadata;
  },
  eventRound: (state, getters) => (status) => {
    let eventRound = null;
    const { nextRounds, round } = getters;
    if (round.status.name === 'open') {
      eventRound = status === 'id' ? round.id : round.displayId;
    } else if (nextRounds.length) {
      eventRound = status === 'id' ? nextRounds[0].id : nextRounds[0].displayId;
    }
    return eventRound;
  },
  taxMneTemplate: (state, getters) => (ticketData) => {
    const formatNumber = '|number_format(2, ",",".")';
    const playedBets = [];
    const translate = getters.translations;
    const ticketBody = [];

    let betIndex;
    let i;

    const header = `${translate.rouletteRoundTitle}: {{roundId}}; ${translate.rouletteTicketCodeTitle}: {{ id }}; {{ ticketDateTimeUTC | date('d.m.Y H:i', false) }}`;
    ticketBody.push(header);

    ticketData.bets.forEach((bet, index) => {
      const len = bet.numEvents > 1 ? bet.numEvents : 1;

      for (i = 0; i < len; i += 1) {
        playedBets.push({
          betIndex: index,
          numEvent: i,
          name: bet.title,
          value: bet.displayValue,
          id: bet.id,
          odd: bet.odds,
          stake: bet.payment,
        });
      }

      // sort by betIndex
      playedBets.sort((a, b) => a.numEvent - b.numEvent);
    });

    for (i = 0; i < playedBets.length; i += 1) {
      if (playedBets[i].numEvent !== betIndex || i === 0) {
        betIndex = playedBets[i].numEvent;
      }
      const betName = `(${playedBets[i].name})`;
      const betValue = `{{bets[${i}].betSelection}}`;
      const betOdd = `{{bets[${i}].betOdds${formatNumber}}}`;
      const betStake = `{{bets[${i}].stakeAmount${formatNumber}}}`;
      const betPayout = '0.00';
      const template = `${betName}; ${betValue}; {{ticketStatus}}; ${translate.rouletteOddTitle}: ${betOdd};
        ${translate.rouletteStakeTitle}: ${betStake}; ${translate.rouletteWinTitle}: ${betPayout};`;
      ticketBody.push(template);
    }

    const payin = `${translate.rouletteTotalPaymentTitle}: {{totalStakeAmount${formatNumber}}}`;
    const payment = `${translate.rouletteTotalPayoutTitle}: 0.00`;
    ticketBody[ticketBody.length - 1] += ` ${payin}; ${payment}`;
    return {
      printTemplate: ticketBody.join(';'),
      timezone: {
        offset: parseInt(getters.user.timezone, 10),
      },
    };
  },
  parseTicketBets: (getters) => (bets, isRebet) => map(bets, (n) => {
    const bet = {
      name: n.name,
      values: n.values ? n.values
        : map(n.betSelection, (value) => (value === '00' ? -1 : value)),
      selection: [],
      label: n.label,
      title: n.title ? n.title : n.displayName,
      displayValue: n.displayValue,
      numbers: n.numbers ? n.numbers : n.betSelection.length,
      numberOfRounds: n.numberOfRounds ? n.numberOfRounds : 1,
      odds: Number(n.odds),
      payment: n.payment ? Number(n.payment) : Number(n.paymentAmount),
    };

    bet.selection = map(bet.values, (value) => ({ number: value }));

    if (isRebet && bet.payment < getters.limits.bet.ticketBetMinPayment) {
      bet.payment = getters.limits.bet.ticketBetMinPayment;
    }
    return bet;
  }),
};
