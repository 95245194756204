import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: {
        name: 'Shop',
      },
    },
    {
      path: '/terminal',
      alias: '/home',
      component: () => import(/* webpackChunkName: "terminalBase" */'@/pages/Terminal'),
      children: [
        {
          path: '',
          name: 'Terminal',
          component: () => import(/* webpackChunkName: "terminal" */'@/components/Terminal'),
        },
        {
          path: 'results',
          name: 'Results',
          component: () => import(/* webpackChunkName: "terminalResults" */'@/components/Results'),
          beforeEnter: (to, from, next) => {
            if (!store.getters.route.name) {
              store.dispatch('setRoute', to.name);
            }
            next();
          },
        },
        {
          path: 'ticket-check',
          name: 'TicketCheck',
          component: () => import(/* webpackChunkName: "terminalTicketCheck" */'@/components/TicketCheck'),
        },
        {
          path: '/ticket-view',
          name: 'TicketViewTerminal',
          component: () => import(/* webpackChunkName: "TicketViewTerminal" */'@/components/TicketViewTerminal'),
        },
      ],
    },
    {
      path: '/shop',
      component: () => import(/* webpackChunkName: "shop" */'@/pages/Shop'),
      children: [
        {
          path: '',
          name: 'Shop',
          component: () => import('@/components/Shop'),
        },
      ],
    },
    {
      path: '',
      component: () => import(/* webpackChunkName: "web" */'@/pages/Web'),
      children: [
        {
          path: '/mobile-web',
          name: 'MobileWeb',
          component: () => import(/* webpackChunkName: "webMobile" */'@/components/MobileWeb'),
        },
        {
          path: '/web',
          name: 'Web',
          component: () => import(/* webpackChunkName: "webDesktop" */'@/components/Web'),
          children: [],
        },
        {
          path: '/ticket-list',
          name: 'TicketList',
          component: () => import(/* webpackChunkName: "ticketList" */'@/components/TicketList'),
        },
        {
          path: '/code-generator',
          name: 'CodeGenerator',
          component: () => import(/* webpackChunkName: "codeGenerator" */'@/components/CodeGenerator'),
        },
      ],
    },
  ],
});
