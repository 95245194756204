import axios from 'axios';
import { assign, defaultTo } from 'lodash';

const http = axios.create({
  timeout: 10000,
});

const platformGetBalance = {
  seven: async (config, token) => {
    const { clientType } = config.platformConfig;

    const headers = {
      Authorization: `Bearer ${token}`,
      'HTTP-X-SEVEN-CLUB-UUID': config.tenantId,
      'X-NSFT-WALLET-USERGROUP': config.tenantId,
      'HTTP-X-NAB-DP': config.applicationName,
      'SEVEN-LOCALE': config.locale,
    };
    const baseUrl = process.env.VUE_APP_SEVEN_API;

    // handle different dc
    // const baseUrl = template(process.env.VUE_APP_SEVEN_API)({
    //   dc: config.platformDataCenter ? `-${config.platformDataCenter}` : '',
    // });

    const url = clientType === 'token' ? `${baseUrl}/wallet/b2b/loggeduser/balance` : `${baseUrl}/web/profile/balance.json`;

    const response = await http.get(url, {
      headers,
    });

    const { data } = response;

    return {
      balance: data.balance,
      currency: data.currency,
    };
  },
};

const platformAuth = {
  seven: async (config, token) => {
    let currentToken = token;
    let thirdPartyToken;

    // const { platformDataCenter } = config;
    const { clientType, playerId } = config.platformConfig;

    const headers = {
      Authorization: `Bearer ${currentToken}`,
      'HTTP-X-SEVEN-CLUB-UUID': config.tenantId,
      'HTTP-X-NAB-DP': config.applicationName,
      'SEVEN-LOCALE': config.locale,
      'SEVEN-TP-TOKEN': token,
    };

    const baseUrl = process.env.VUE_APP_SEVEN_API;

    // const baseUrl = template(process.env.VUE_APP_SEVEN_API)({
    //   dc: platformDataCenter ? `-${platformDataCenter}` : '',
    // });

    if (clientType === 'token') {
      try {
        const authResult = await http.post(`${baseUrl}/users/b2b/authenticate.json`, {
          id: playerId,
          token: currentToken,
          authStrategy: clientType,
        }, {
          headers,
        });

        thirdPartyToken = currentToken;
        currentToken = authResult.headers['access-token'];
      } catch (error) {
        currentToken = undefined;
      }
    }

    assign(headers, {
      Authorization: `Bearer ${currentToken}`,
    });

    const responses = await Promise.all([
      http.get(`${baseUrl}/jwt/login_check`, {
        headers,
      }),
      platformGetBalance.seven(config, currentToken),
    ]);

    const playerData = responses[0].data;
    const balanceData = responses[1];

    return {
      uuid: playerData.Uuid,
      balance: balanceData.balance,
      currency: balanceData.currency,
      timezone: playerData.timezone,
      username: playerData.username,
      firstName: playerData.firstName,
      lastName: playerData.lastName,
      auth: {
        token: currentToken,
        thirdPartyToken,
      },
    };
  },
};

export default {
  async authenticate(config, authToken) {
    const qp = new URLSearchParams(window.location.search);
    const token = defaultTo(authToken, qp.get('token'));

    if (token && token !== 'undefined' && token !== 'null' && token !== '{token}') {
      const data = await platformAuth[config.platformName](config, token);
      return data;
    }

    return undefined;
  },
  async getBalance(config, token) {
    const platformMethod = platformGetBalance[config.platformName];

    if (platformMethod) {
      const data = await platformMethod(config, token);
      return data;
    }

    return undefined;
  },
};
