import { has } from 'lodash';
import { bus } from '@/utility';
import { gatewayApi } from '@/api';

export default {
  init() {
    window.onParentMessage = (message, payload) => {
      const modifiedData = JSON.stringify(payload);
      const data = JSON.parse(modifiedData);
      bus.$emit(message, data);
    };
    if (has(window, 'WebAppListener')) {
      WebAppListener.sendMessage('Slave.Init');
    } else {
      gatewayApi.init();
    }
  },
  isActive() {
    return navigator.userAgent.includes('wv');
  },
};
