<template>
  <transition name="preloader-wrapper">
    <div class="preloader">
      <div class="preloader-content">
        <div class="logo"></div>
        <div class="game-title">
          <span v-if="productNameOverride">{{productNameOverride}}</span>
          <localized-label v-else>rouletteProductTitle</localized-label>
        </div>
        <div class="progress-bar">
          <div class="progress-bar-fill"
               :style="{width: `${progressBar}%`}">
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { queryStringParser } from '@/utility';

export default {
  name: 'Preloader',
  data() {
    return {
      interval: null,
      progressBar: 0,
    };
  },
  props: {
    delay: {
      type: Number,
    },
    maxProgress: {
      type: Number,
    },
    progress: {
      type: Number,
    },
    update: {
      type: Number,
    },
  },
  created() {
    this.interval = setInterval(() => { this.setProgress(); }, this.delay);
  },
  watch: {
    progress(update) {
      if (update) {
        this.progressBar = update;
      }
    },
  },
  methods: {
    setProgress() {
      if (this.progressBar >= this.maxProgress) {
        clearInterval(this.interval);
      }
      this.progressBar += this.update;
    },
  },
  computed: {
    productNameOverride() {
      const qp = queryStringParser.parse(window.location.search) || false;
      return qp?.productNameOverride || false;
    },
  },
};
</script>

<style lang="scss">
  .preloader {
    position: fixed;
    z-index: 600;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    .preloader-content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .logo {
        width: 70px;
        height: 70px;
        margin-bottom: 8px;
      }
      .game-title {
        margin-top: 10px;
        text-align: center;
        font-size: 32px;
        font-weight: bold;
        text-transform: uppercase;
        font-stretch: condensed;
      }
      .progress-bar {
        margin-top: 50px;
        position: relative;
        width: 300px;
        height: 4px;
        overflow: hidden;
        .progress-bar-fill {
          height: 4px;
        }
      }
    }
  }
</style>
