<template>
  <div id="app">
    <div v-if="false"
         :class="theme">
      <Preloader :delay="100"
                 :maxProgress="100"
                 :progress="preloaderProgress"
                 :update="10">
      </Preloader>
    </div>
    <div v-if="true">
      <v-app v-if="isTerminal">
        <router-view></router-view>
      </v-app>
      <div v-else
           class="application-wrapper">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { toLower } from 'lodash';
import { queryStringParser } from '@/utility';
import { gtagPageView } from '@/utility/gtag';
import Preloader from '@/components/Preloader';

export default {
  name: 'App',
  components: {
    Preloader,
  },
  data() {
    return {
      theme: 'theme',
    };
  },
  created() {
    this.qp = queryStringParser.parse(window.location.search) || false;
    this.theme = this.qp.theme ? `${this.theme}-${this.qp.theme}` : `${this.theme}-${'dark'}`;
    if (process.env.NODE_ENV === 'local') {
      this.$store.dispatch('loadConfig', this.$router.currentRoute.path.substring(1));
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'platform',
      'preloader',
      'preloaderProgress',
      'route',
      'wheel',
    ]),
    channel() {
      return this.platform && this.platform.channel;
    },
    isTerminal() {
      return this.channel === 'terminal';
    },
    checkRoute() {
      return this.route.name === 'Results';
    },
  },
  watch: {
    platform(value) {
      if (value && value.channel) {
        if ((value.channel === 'web' || toLower(value.channel) === 'mobile') && this.isMobile) {
          const routeName = this.qp.isCodeGenerator === 'true' ? 'CodeGenerator' : 'MobileWeb';
          this.$router.replace({
            name: routeName,
            query: { ...this.$route.query },
          });
        }
      }
    },
  },
  mounted() {
    gtagPageView(this.$route.path);
  },
};
</script>

<style lang="scss">
#app {
  font-family: "Roboto";
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .application-wrapper {
    min-height: 100%;
    flex: 1 1 auto;
  }
}
.application--wrap {
  height: 100%;
}
::-webkit-scrollbar {
  width: 0px;
}
</style>
