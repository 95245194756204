export default {
  ACTIVATE_THIRD_PARTY_INTEGRATION: 'ACTIVATE_THIRD_PARTY_INTEGRATION',
  ACTIVATE_LIGHTNING_INTEGRATION: 'ACTIVATE_LIGHTNING_INTEGRATION',
  ADD_TICKET_TO_TICKET_STORE: 'ADD_TICKET_TO_TICKET_STORE',
  CALC_TICKET_MAX_PAYMENT: 'CALC_TICKET_MAX_PAYMENT',
  CLEAR_TICKET: 'CLEAR_TICKET',
  REMOVE_TICKET_BET: 'REMOVE_TICKET_BET',
  REMOVE_TICKET_FROM_TICKET_STORE: 'REMOVE_TICKET_FROM_TICKET_STORE',
  SET_ALLOWED_SHORTCUT: 'SET_ALLOWED_SHORTCUT',
  SET_AUTH: 'SET_AUTH',
  SET_BACK_BUTTON_VISIBILITY: 'SET_BACK_BUTTON_VISIBILITY',
  SET_BALANCE: 'SET_BALANCE',
  SET_BALANCE_VISIBILITY: 'SET_BALANCE_VISIBILITY',
  SET_CHANNEL_MOCK: 'SET_CHANNEL_MOCK',
  SET_CHECKED_TICKET: 'SET_CHECKED_TICKET',
  SET_CONFIG: 'SET_CONFIG',
  SET_CREATION_TYPE: 'SET_CREATION_TYPE',
  SET_DEFAULT_CHIP: 'SET_DEFAULT_CHIP',
  SET_ERROR_MESSAGE: 'SET_ERROR_MESSAGE',
  SET_GENERATOR_CODE: 'SET_GENERATOR_CODE',
  SET_INFO_MESSAGE: 'SET_INFO_MESSAGE',
  SET_LAST_PLAYED_TICKET: 'SET_LAST_PLAYED_TICKET',
  SET_LAST_PLAYED_TICKETS: 'SET_LAST_PLAYED_TICKETS',
  SET_LIMITS: 'SET_LIMITS',
  SET_NEXT_ROUNDS: 'SET_NEXT_ROUNDS',
  SET_NUMBER_OF_PLAYED_TIKCETS: 'SET_NUMBER_OF_PLAYED_TIKCETS',
  SET_PALETTE: 'SET_PALETTE',
  SET_PAY_TABLE: 'SET_PAY_TABLE',
  SET_PLATFORM: 'SET_PLATFORM',
  SET_PRELOADER: 'SET_PRELOADER',
  SET_PRELOADER_PROGRESS: 'SET_PRELOADER_PROGRESS',
  SET_PRESET: 'SET_PRESET',
  SET_PRESETS: 'SET_PRESETS',
  SET_REBET_BETS: 'SET_REBET_BETS',
  SET_REMOVE_BUTTON_STATUS: 'SET_REMOVE_BUTTON_STATUS',
  SET_REMOVE_CHIP: 'SET_REMOVE_CHIP',
  SET_ROULETTE_RESULTS: 'SET_ROULETTE_RESULTS',
  SET_ROUND: 'SET_ROUND',
  SET_ROUTE: 'SET_ROUTE',
  SET_SHORTCUTS: 'SET_SHORTCUTS',
  SET_SITE_REFERER: 'SET_SITE_REFERER',
  SET_THEME: 'SET_THEME',
  SET_THIRD_PARTY_TOKEN: 'SET_THIRD_PARTY_TOKEN',
  SET_TICKET_BET: 'SET_TICKET_BET',
  SET_TICKET_PAYMENT: 'SET_TICKET_PAYMENT',
  SET_TOTAL_BET_STAKE: 'SET_TOTAL_BET_STAKE',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  SET_USER: 'SET_USER',
  UPDATE_BET: 'UPDATE_BET',
  UPDATE_BETSLIP_BET: 'UPDATE_BETSLIP_BET',
  UPDATE_BET_PAYMENT: 'UPDATE_BET_PAYMENT',
  UPDATE_CLEAR_TABLE_TOOLTIP_STATUS: 'UPDATE_CLEAR_TABLE_TOOLTIP_STATUS',
  UPDATE_LAST_TICKETS_TOOLTIP_STATUS: 'UPDATE_LAST_TICKETS_TOOLTIP_STATUS',
  UPDATE_TICKET: 'UPDATE_TICKET',
  UPDATE_TICKET_BETS: 'UPDATE_TICKET_BETS',
};
