import http from './http';

export default {
  getResults({
    wheel, wheelIdentifier, companyUuid, locale, pageSize,
  }) {
    return http.get(`/public/rounds/${wheel}/${wheelIdentifier}/${companyUuid}?locale=${locale}&pageSize=${pageSize}`, {
    }).then((response) => {
      const result = response.data;
      return result;
    });
  },
};
