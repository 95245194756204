import axios from 'axios';
import { localStorage } from '@/utility';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000,
});

http.interceptors.request.use((config) => {
  const transformedConfig = config;
  transformedConfig.headers.authorization = `Bearer ${localStorage.getItem('auth')}`;

  return transformedConfig;
}, (error) => Promise.reject(error));

export default http;
