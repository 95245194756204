import {
  each,
  has,
  isArray,
  keys,
  clone,
} from 'lodash';
import store from '@/store';

export default {
  roundNumber(amount) {
    return +(`${Math.round(Number(`${amount}e+2`))}e-2`);
  },
  numberFormatter(value) {
    let modifiedValue = null;
    const labels = store.getters.config.wheel.definition.numbers.labels || null;
    if (isArray(value)) {
      modifiedValue = clone(value);
      each(modifiedValue, (number, key) => {
        if (labels && has(labels, number)) {
          modifiedValue.splice(key, 1, labels[number]);
        }
      });
    }

    each(keys(labels), (key) => {
      if (labels[key] === value) {
        modifiedValue = key;
      }

      if (key === value.toString()) {
        modifiedValue = labels[key];
      }
    });

    return modifiedValue || value;
  },
};
