import state from './state';
import getters from './getters';
import actions from './actions';
// eslint-disable-next-line import/no-cycle
import mutations from './mutations';
import persistAuthPlugin from './plugins/persistAuth';

export default {
  state,
  getters,
  actions,
  mutations,
  plugins: [persistAuthPlugin],
  strict: true,
};
