import http from './http';

export default {
  getList({
    wheel,
    wheelIdentifier,
    locale,
    player,
    params,
  }) {
    const page = params.page || 1;
    const size = params.size || 10;

    return http.get(`tickets/${wheel}/players/${player}`, {
      params: {
        wheelAccountId: wheelIdentifier,
        includeBets: params.includeBets,
        dateFrom: params.dateFrom,
        dateTo: params.dateTo,
        page,
        locale,
        size,
      },
    }).then((response) => response.data);
  },
};
