import { cloneDeep, each } from 'lodash';
import store from '@/store';
import { lightningIntegration } from '@/utility';
import http from './httpSeven';

export default {
  createTicket({
    additionalInfo,
    headers,
    metadata,
    reqUuid,
    ticket,
    tenantEndpoint,
  }) {
    if (tenantEndpoint) {
      http.defaults.baseURL = tenantEndpoint;
    }
    return http.post(`/web/tickets/add.json?requestUuid=${reqUuid}`, {
      additionalInfo,
      metadata,
      ticket,
    },
    {
      headers,
    }).then((response) => response);
  },
  ticketUpdate(data, gateway) {
    const statusData = {
      game: 'Roulette',
      message: `ticket:${data.action}Response`,
      ticketData: data,
    };
    if (gateway) {
      gateway.sendMessage({
        action: statusData.message,
        data: statusData,
        enforceEvent: true,
      });
    }

    if ((data?.action === 'add')
      && (gateway || store.getters.hasWindowWebAppListener || store.getters.isLightningIntegration)
      && store.getters.isTicketsResolvedEventSendable) {
      if (gateway) {
        const ticketData = !store.getters.channelViewOverride ? data : this.formatPrintTicket(data);
        gateway.sendMessage({
          action: 'Tickets.Resolved',
          data: {
            accepted: true,
            ticket: ticketData,
          },
        });
      }
      if (store.getters.hasWindowWebAppListener) {
        WebAppListener.sendMessage('Tickets.Resolved');
      }

      if (store.getters.isLightningIntegration && store.getters.manualBalanceUpdate) {
        lightningIntegration.updateBalance();
        lightningIntegration.clearManualBalanceUpdate();
        lightningIntegration.startManualBalanceUpdate();
      }
    }
    if ((data?.action === 'cancel' || data?.action === 'payout') && this.gateway) {
      store.dispatch('setCheckedTicket', data);
    }
  },
  formatPrintTicket(data) {
    const ticket = cloneDeep(data);
    each(ticket.bets, (ticketBet) => {
      const bet = ticketBet;
      const printBet = {
        displayName: bet.displayName,
        odds: bet.odds,
        stake: bet.stake,
        possiblePayout: bet.possiblePayout,
        values: bet.betSelection,
      };
      bet.bet = printBet;
    });
    return ticket;
  },
};
