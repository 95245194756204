import {
  assign,
  get,
  isEmpty,
  includes,
  isObject,
  isNumber,
  merge,
  toLower,
  findIndex,
  each,
  template,
  has,
  cloneDeep,
} from 'lodash';
import { Money } from '@nsftx/money-js/dist/bundle.umd';
import { v4 } from 'uuid';
import gravityGateway from '@nsftx/seven-gravity-gateway';
import BarcodeScanPlugin from '@nsftx/seven-gravity-gateway/plugin-barcode-scan';
import {
  bus,
  formatter,
  queryStringParser,
  ticketChecker,
  tenantConfig,
} from '@/utility';
import { pusher } from '@/plugins';
import { resultsApi, ticketApi } from '@/api';
import store from '@/store';
import router from '@/router';

export default {
  activateTicket(message) {
    bus.$emit('FocusBetInput');

    this.setTicketStatus(message);

    this.gateway.sendMessage({
      action: 'Betslip.ActivateTicket',
      data: {
        betslipId: store.getters.config.gateway.slaveId,
        ticketId: store.getters.betslip.tickets[0].id,
      },
    });
  },
  setTicketStatus(message) {
    const hasHomeRoute = has(message.data, 'route');
    const hasCheckRoute = has(message.data.data, 'route');
    const hasTicket = has(message.data.data, 'ticket');

    if (hasTicket) {
      store.dispatch('setCheckedTicket', message.data.data.ticket);
    }
    if (hasHomeRoute || hasCheckRoute) {
      const route = message.data.route ? message.data.route : message.data.data.route;

      if (toLower(route.to) === 'results') {
        const event = get(store.getters.roundEvent, 'name');
        const eventDuration = store.getters.roundEventDuration;
        const inProgress = store.getters.isRoundInProgress;
        if (inProgress && event === 'draw') {
          setTimeout(() => { this.getRouletteResults(); }, eventDuration * 1000);
        } else {
          this.getRouletteResults();
        }
      }
      const path = toLower(route.to) !== 'home'
        ? `/${toLower(store.getters.platform.channel)}/${route.to}`
        : `/${toLower(store.getters.platform.channel)}`;
      router.push(path).catch((error) => error);
    }
  },
  onBetslipReset() {
    // platform should send event for payin confirmation in the future
    // until then Betslip.Reset event is used for both types of payin
    if (!store.getters.inputErrorMessage) {
      store.dispatch('clearTicket').then(() => {
        bus.$emit('FocusBetInput');
        bus.$emit('ticketChange', store.getters.ticket);
      });
    }
  },
  updateTicketPayment() {
    const { ticket, totalPayment, currency } = store.getters;
    const money = Money.createFromMainUnit(totalPayment, currency);
    store.dispatch('updateTicket', {
      bets: ticket.bets,
      payment: money.getAmountAsMainUnit(),
      payin: money.getAmountAsMainUnit(),
      winnings: ticket.winnings,
    });
  },
  createBetslip() {
    const { wheelTickets } = store.getters;
    const betslip = merge({
      tickets: [
        {
          group: {
            taxesConfig: {
              payin: {
                policy: wheelTickets.ticketPayinTaxPolicy,
              },
              payout: {
                policy: wheelTickets.ticketPayoutTaxPolicy,
              },
            },
          },
        },
      ],
    }, store.getters.betslip);

    this.gateway.sendMessage({
      action: 'Betslip.Create',
      data: {
        betslip,
      },
    });
  },
  focusTicketPayment() {
    this.gateway.sendMessage({
      action: 'FocusNext',
      data: [31],
    });
  },
  loadSettings(message) {
    const { data } = message;
    const qp = queryStringParser.parse(window.location.search) || {};

    /*
    This mapping will change
    https://phabricator.nsoft.ba/T45825
    */
    const betshop = get(data, 'betshop') || {};
    const channel = get(data, 'dp') || 'web'; // web channel should add info about dp.
    const device = get(data, 'device') || {};
    const user = get(data, 'user') || {};
    const product = get(data, 'product') || {};
    const company = get(data, 'company') || {};
    const currency = get(company, 'currency') || get(data, 'currency') || get(user, 'currency') || {};
    const terminal = get(data, 'terminal');
    const integration = get(data, 'integration');
    const locale = get(data, 'locale');
    const cmsAppSettingsConfig = get(data, 'deprecated.cms.appSettings.config');

    if (qp.theme) {
      store.dispatch('setTheme', qp.theme);
    } else if (qp.palette && qp.palette.includes('Light')) {
      store.dispatch('setTheme', 'light');
    } else {
      store.dispatch('setTheme', 'dark');
    }

    if (qp.palette) {
      store.dispatch('setPalette', qp.palette);
    } else if (qp.theme && qp.theme === 'light') {
      store.dispatch('setPalette', 'blueLight');
    } else {
      store.dispatch('setPalette', 'blue');
    }

    if (integration) {
      const token = data.auth?.tpToken || qp.token;
      store.dispatch('activateTPIntegration');
      store.dispatch('setThirdPartyToken', token);
      store.dispatch('setSiteReferer', window.location.href);
    }

    if (qp.showBalance) {
      store.dispatch('setBalanceVisibility', qp.showBalance);
    }

    if (qp.backButtonEnabled) {
      store.dispatch('setBackButtonVisibility', qp.backButtonEnabled);
    }

    store.dispatch('loadConfig', {
      wheelIdentifier: product.cpvUuid,
      locale: locale.iso1 || company.locale || user.language || qp.language,
      app: toLower(channel) === 'retail' ? 'RouletteShop' : 'RouletteWeb',
    }).then(() => {
      const currencyOverride = store.getters.config?.wheel.wheelAccountCurrency
      || (isObject(currency) ? currency.symbol : currency);
      store.dispatch('setPlatform', {
        betshopId: betshop ? betshop.id : null,
        betshopUuid: betshop ? betshop.uuid : null,
        cashRegisterUuid: device ? device.cashRegisterUuid : null,
        channel: this.setChannel(channel),
        cmsAppSettingsConfig,
        isMobile: qp.isMobile || null,
        isIntegration: integration || null,
        companyId: company ? company.id : null,
        companyUuid: company.uuid || company.id || null,
        companyName: company.name || null,
        companyLanguage: company.language || qp.language,
        currency: currencyOverride,
        deviceId: betshop.device ? betshop.device.deviceId : device.id,
        deviceToken: betshop.device ? betshop.device.token : device.token,
        deviceUuid: device.uuid || null,
        productId: product.cpvUuid || qp.cpvUuid,
        productUuid: product.uuid || qp.cpvUuid,
        productShortcut: product.shortcut || null,
        productName: product.id || null,
        productTitle: product.translation || null,
      }).then(() => {
        this.updateSettings();
      });
      if (terminal) {
        store.dispatch('setBalance', terminal.balance);
      }
      if (user && !isEmpty(user)) {
        store.dispatch('setUser', {
          user: {
            id: data.user.profile ? data.user.profile.uuid : user.id,
            uuid: data.user.profile ? data.user.profile.uuid : user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            name: user.name,
            language: user.language || null,
            settings: user.settings || null,
            logged: user.logged || false,
            timezone: data.user.profile?.timezoneOffset,
            auth: {
              token: user.auth ? user.auth.token : user.token,
            },
          },
        });
        if (user.balance >= store.getters.balanceAmount && !terminal) {
          store.dispatch('setBalance', user.balance);
        }
      }
      if (this.gateway) {
        this.createBetslip();
      }
      store.dispatch('setLimits');
      store.dispatch('setPresets');
      if (store.getters.socketProvider === 'pusher') {
        if (store.getters.isWebChannel && !store.getters.user.auth.token) return;
        pusher.init();
      }
    });
  },
  gateway: null,
  init() {
    const self = this;
    const { config } = store.getters;
    const Gateway = gravityGateway.slave;
    const qp = queryStringParser.parse(window.location.search) || {};

    this.gateway = new Gateway({
      slaveId: config.gateway.slaveId,
      allowedOrigins: config.gateway.masterOrigins,
      debug: process.env.NODE_ENV !== 'production',
      data: {},
      plugins: qp.application === 'shop' ? [new BarcodeScanPlugin()] : [],
      eventPropagation: {
        click: true,
        keydown: '*',
      },
      eventListeners: {
        keydown: ['82', '87', '107', '83', '9', '13', '70', '111', '187'],
      },
      load(message) {
        self.loadSettings(message);
      },
    });
  },
  validateBetslip(ticket) {
    let notification = null;
    let valid = true;
    const { limits } = store.getters;

    each(ticket.bets, (bet) => {
      /* eslint-disable */
      if (isNumber(store.getters.balanceAmount) && (ticket.payment > store.getters.balanceAmount)) {
        notification = template(store.getters.translations.invalidBalance)(limits.ticket);
        this.sendNotification(notification);
        valid = false;
      } else if (bet.payment < limits.bet.ticketBetMinPayment) {
        notification = template(store.getters.translations.invalidBetPayment)(limits.bet);
        this.sendNotification(notification);
        valid = false;
      } else if (bet.payment > limits.bet.ticketBetMaxPayment) {
        notification = template(store.getters.translations.invalidBetPayment)(limits.bet);
        this.sendNotification(notification);
        valid = false;
      } else if (ticket.payment < limits.ticket.ticketMinPayment) {
        notification = template(store.getters.translations.invalidTicketPayment)(limits.ticket);
        this.sendNotification(notification);
        valid = false;
      } else if (ticket.payment > limits.ticket.ticketMaxPayment) {
        notification = template(store.getters.translations.invalidTicketPayment)(limits.ticket);
        this.sendNotification(notification);
        valid = false;
      }
    });

    return valid;
  },
  onTicketHistoryFetch({ data }) {
    bus.$emit('fetchTicketHistory', data);
  },
  onTicketChecked({ data }) {
    bus.$emit('ticketChecked', data);
  },
  onCheckedWebCode({ data }) {
    if (!store.getters.isBettingDisabled) {
      const code = data.code.split('-')[1];
      store.dispatch('getTicketByWebCode', code);
      store.dispatch('setCreationType', 'code');
    }
  },
  notifyTicketHistoryFetched(totalCount) {
    this.gateway.sendMessage({
      action: 'Tickets.HistoryFetched',
      data: {
        params: {
          totalCount,
        },
      },
    });
  },
  notifyNavigationRequest() {
    this.gateway.sendMessage({
      action: 'Router.GoToRoute',
      data: {
        state: {
          name: 'PlayerHistory',
          params: {
            section: 'games',
            type: 'roulette',
          },
        },
      },
    });
  },
  returnToLobby() {
    this.gateway.sendMessage({
      action: 'Router.GoToRoute',
      data: {
        state: {
          name: 'Games',
        },
      },
    });
  },
  sendNotification(notification) {
    store.dispatch('setErrorMessage', notification);
    store.dispatch('setInfoMessage', {
      message: notification,
      messageType: 'error-style',
      icon: 'n-i-void',
      closeIconActive: false,
      delay: 1500,
    });
    if (this.gateway) {
      this.gateway.sendMessage({
        action: 'Notification:Show',
        data: [{
          message: notification,
          type: 'warning',
          delay: 2000,
          actions: false,
        },
        ],
      });
    }
  },
  onBetSelect(message) {
    const { bet } = message.data;
    store.dispatch('updateBet', bet);
    bus.$emit('selectBet', bet);
  },
  onUpdateSelectedBet() {
    bus.$emit('updateSelectedBet');
  },
  onBetRemove() {
    bus.$emit('betRemove');
  },
  onTicketPreprint(message) {
    const { data } = message;
    each(data.responseData.bets, (bet) => {
      /* eslint-disable no-param-reassign */
      bet.betSelection = formatter.numberFormatter(bet.betSelection);
    });
    this.printTicket(data);
  },
  onTicketRebet({ data }) {
    const { ticket } = data;
    store.dispatch('clearTicket');
    if (!store.getters.isTerminal) {
      const bets = store.getters.parseTicketBets(ticket.bets, true);
      store.dispatch('setShopRebetTicket', bets);
      bus.$emit('FocusBetInput');
      bus.$emit('ticketChange', ticket);
    } else {
      /* Terminal ticket-check, on rebet wait until
        router change route and load roulette table */
      setTimeout(() => {
        bus.$emit('rebetTicket', ticket);
        store.dispatch('setTicketPayment');
        router.push('home').catch((error) => error);
      }, 500);
    }
  },
  onBalanceChanged(message) {
    const { data } = message;
    store.dispatch('setBalance', data.balance);
  },
  onAuthorizationChanged(message) {
    // listen to AuthorizationChanged only on web channel
    // enable this if needed for 3rd party terminal integration
    if (store.getters.directPayin) {
      const { data } = message;
      const user = {
        id: data.auth.user.id,
        uuid: data.auth.user.id,
        firstName: data.auth.user.firstName,
        lastName: data.auth.user.lastName,
        language: data.auth.user.profile ? data.auth.user.profile.language
          : store.getters.platform.companyLanguage,
        settings: data.auth.user.settings,
        logged: data.auth.user.logged,
        name: data.auth.user.name,
        currency: data.auth.user.currency || data.auth.currency,
        auth: {
          token: data.auth.user.token,
        },
      };
      if (user.id) {
        bus.$emit('closeHistoryPopup');
      } else {
        store.dispatch('clearUserPresets');
      }
      if (data.auth.user.balance !== 0) {
        store.dispatch('setBalance', data.auth.user.balance);
      }
      store.dispatch('setUser', { user });
      pusher.init();
    }
  },
  getRouletteResults() {
    const { config } = store.getters;
    const { platform } = store.getters;
    const { wheelBets } = store.getters;
    const payload = {
      wheel: config.wheel.id,
      wheelIdentifier: config.wheel.wheelAccountId,
      locale: config.locale,
      companyUuid: platform.companyUuid,
      pageSize: 10,
    };

    resultsApi.getResults(payload).then((response) => {
      const results = get(response, 'rounds');
      store.dispatch('setRouletteResults', {
        roundsResult: results,
        rouletteBets: wheelBets,
      });
    });
  },
  onWidgetEvent(message) {
    const { data } = message;

    if (data) {
      /*
      TODO:
      This should be sent as type="ShortcutClicked".
      Currenly it acts as mouse event.
      Talk to @miletic.dinko about it.
      */
      if (data.shortcut === 'Q' && store.getters.isRetail) {
        this.printResults();
        return;
      }
      if (data.shortcut === 'CTRL+M') {
        bus.$emit('FocusBetInput');
        return;
      }
      if (data.shortcut) {
        bus.$emit('ShortcutClicked', data.shortcut);
      }
    }
  },
  printResults() {
    const { config } = store.getters;
    const { platform } = store.getters;
    const payload = {
      wheel: config.wheel.id,
      wheelIdentifier: config.wheel.wheelAccountId,
      locale: config.locale,
      companyUuid: platform.companyUuid,
      pageSize: 10,
    };

    resultsApi.getResults(payload).then((results) => {
      each(results.rounds, (round) => {
        /* eslint-disable no-param-reassign */
        round.result = formatter.numberFormatter(round.result);
      });
      this.sendPrintTemplate('results', 'rouletteResults', results);
    });
    bus.$emit('FocusBetInput');
  },
  sendPrintTemplate(type, npsJobName, value) {
    this.gateway.sendMessage({
      action: 'Peripherals.Print',
      data: {
        productId: 'Roulette',
        type,
        npsJobName,
        data: value,
        ticketData: value,
      },
    });
  },
  onUIShow(value) {
    if (includes(value.data.name, 'TicketHistory')) {
      router.push('/ticket-view').catch((error) => error);
    }
  },
  openPlayerRegistrationForm() {
    if (this.gateway) {
      this.gateway.sendMessage({
        action: 'User.LoginRequired',
      });
    }
  },
  payTicketOverIntegrator() {
    this.updateTicketPayment();
    const ticket = cloneDeep(assign(store.getters.ticketConfiguration, store.getters.ticket));
    if (ticket.round.id && ticket.round.displayId) {
      if (this.validateBetslip(ticket)) {
        this.gateway.sendMessage({
          action: 'Tickets:Pay',
          data: {
            validateTicket: false,
            ticket,
            ticketFormatted: ticket,
          },
        });
        store.dispatch('setLastPlayedTicket', ticket);
        // on shop channel Betslip.Reset event clear betslip ticket
        if (store.getters.channel !== 'shop') {
          store.dispatch('clearTicket')
        }
        // clear error or alert message from seven after payin
        if (store.getters.inputErrorMessage) {
          store.dispatch('setErrorMessage', null);
        }
        store.dispatch('setCreationType', 'default');
        bus.$emit('clearChips');
      }
    }
  },
  payInTicket() {
    const ticket = assign(store.getters.ticketConfiguration, store.getters.ticket);
    if (ticket.round.id && ticket.round.displayId) {
      if (this.validateBetslip(ticket)) {
        const reqUuid = v4();
        const metadata = store.getters.metadata(reqUuid);
        const headers = store.getters.headers;
        const additionalInfo = store.getters.wheelTickets.ticketPayinTaxPolicy === 'MNEPayinTax'
          ? getters.taxMneTemplate(ticket) : {};
        store.dispatch('setInfoMessage', {
          message: store.getters.translations.rouletteInfoBarTicketProcess,
          messageType: 'warning-style',
          icon: 'n-i-status-b',
          closeIconActive: false,
        });
        store.dispatch('clearTicket');
        bus.$emit('clearChips');
        bus.$emit('ticketIsProcessing');
        const companyUuid = store.getters.platform.companyUuid;
        const localTenantConfiguration = tenantConfig.getTenantSettings(companyUuid);
        return ticketApi.createTicket({
          additionalInfo,
          headers,
          metadata,
          reqUuid,
          ticket,
          tenantEndpoint: localTenantConfiguration ? localTenantConfiguration.endpoints.payin : false,
        }).then((response) => {
            const result = response.data;
            const ticketIndex = findIndex(store.getters.ticketStore, ['requestUuid', result.requestUuid]);
            if (ticketIndex > -1) {
              store.dispatch('removeTicketFromTicketStore', result);
            } else {
              store.dispatch('addTicketToTicketStore', result);
              ticketChecker.startChecker(result.requestUuid);
            }
          }).catch((error) => {
            if (error.response?.status === 403) this.logoutUser();

            const message = error.response ? error.response.data.message : error;
            const errorCode = error.response ? error.response.data.code : 0;
            const isVerification = errorCode === 11000;
            const closeIconActive = isVerification;
            const delay = isVerification ? 5000 : 2000;

            store.dispatch('setInfoMessage', {
              message,
              messageType: 'error-style',
              icon: 'n-i-void',
              closeIconActive,
              delay,
              action: isVerification ? () => {
                this.gateway.sendMessage({
                  action: 'UI.Show',
                  data: {
                    name: ['Betslip', 'PlayerVerification'],
                  },
                });
              } : undefined,
            });

            bus.$emit('ticketIsResolved');
        });

      }
    }
  },
  printTicket(ticket) {
    this.gateway.sendMessage({
      action: 'Tickets.Print',
      data: ticket,
      enforceEvent: true,
    });
  },
  onPayingSentSuccess() {
    bus.$emit('ticketIsResolved');
  },
  onPayingFailed() {
    bus.$emit('ticketIsResolved');
  },
  setChannel(value) {
    const defaultChannel = 'Shop';

    if (value) {
      switch (value.toUpperCase()) {
        case 'RETAIL':
          return defaultChannel;
        case 'TERMINAL':
          return value;
        case 'WEB':
          return value;
        case 'MOBILE':
          return value;
        default:
          return defaultChannel;
      }
    }

    return defaultChannel;
  },
  setListeners() {
    if (this.gateway) {
      this.gateway.on('Betslip.Reset', this.onBetslipReset.bind(this));
      this.gateway.on('Slave.Shown', this.activateTicket.bind(this));
      this.gateway.on('Tickets.PrePrint', this.onTicketPreprint.bind(this));
      this.gateway.on('Tickets.ReBet', this.onTicketRebet.bind(this));
      this.gateway.on('User.BalanceChanged', this.onBalanceChanged.bind(this));
      this.gateway.on('User.AuthorizationChanged', this.onAuthorizationChanged.bind(this));
      this.gateway.on('Tickets.FetchHistory', this.onTicketHistoryFetch.bind(this));
      this.gateway.on('Tickets.Checked', this.onTicketChecked.bind(this));
      this.gateway.on('Ticket.CheckWebCode', this.onCheckedWebCode.bind(this));
      this.gateway.on('Tickets.PayingSentSuccess', this.onPayingSentSuccess.bind(this));
      this.gateway.on('Tickets.PayingFailed', this.onPayingFailed.bind(this));
      this.gateway.on('Widget.Event', this.onWidgetEvent.bind(this));
      this.gateway.on('UI.Show', this.onUIShow.bind(this));
    }
    bus.$on('openPlayerRegistrationForm', this.openPlayerRegistrationForm.bind(this));
    bus.$on('payTicket', this.payTicketOverIntegrator.bind(this));
    bus.$on('User.BalanceChanged', this.onBalanceChanged.bind(this));
    bus.$on('User.AuthorizationChanged', this.onAuthorizationChanged.bind(this));
    bus.$on('Slave.Load', this.loadSettings.bind(this));
    bus.$on('ticketUpdate', this.ticketUpdate.bind(this));
    window.addEventListener('keydown', (e) => {
      if (e.key === 'q' && store.getters.isRetail) {
        this.printResults();
      }
      if (e.ctrlKey && e.code === 'KeyM') {
        bus.$emit('focusBetslipFirstBetPayment');
      }
      if (e.key === '+') {
        bus.$emit('FocusBetInput');
      }
      /* Clear retail betslip */
      if (e.key === '/') {
        store.dispatch('clearTicket').then(() => {
          bus.$emit('FocusBetInput');
          bus.$emit('ticketChange', store.getters.ticket);
        });
      }
    });
  },
  ticketUpdate(data) {
    ticketApi.ticketUpdate(data, this.gateway);
  },
  updateSettings() {
    if (this.gateway) {
      this.gateway.sendMessage({
        action: 'Slave.Loaded',
        data: store.getters.channelSettings,
      });
    }
    if (store.getters.hasWindowWebAppListener) {
      WebAppListener.sendMessage('Slave.Loaded');
    }
  },
  logoutUser() {
    this.gateway.sendMessage({
      action: 'User.Logout',
    });
  },
};
