import {
  assign,
  concat,
  each,
  filter,
  find,
  findIndex,
  get,
  has,
  head,
  includes,
  isArray,
  isEmpty,
  map,
  merge,
  omit,
  reduce,
  remove,
  set,
  sumBy,
  toLower,
  toNumber,
  toString,
  uniqBy,
  upperCase,
  upperFirst,
  valuesIn,
} from 'lodash';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import types from './mutationTypes';
import {
  checkTicketBets,
  localStorage,
} from '../utility';

const calculateMaxWinnings = (bets) => {
  const maxWinnings = reduce(bets, (sum, bet) => {
    const add = bet.payment + (bet.payment * bet.odds);
    return sum + add;
  }, 0);

  return maxWinnings;
};

const distributeTotalPayment = (bets, payment, payin) => {
  const paymentPerBet = payment / bets.length;

  each(bets, (bet) => {
    assign(bet, {
      payment: bet.payment || paymentPerBet,
    });
  });

  return {
    payment,
    payin,
    bets,
    winnings: {
      min: 0,
      max: calculateMaxWinnings(bets),
    },
  };
};

const updateBets = (bets) => {
  const ticketBets = each(bets, (bet) => {
    // eslint-disable-next-line
    bet.event = store.getters.round.displayId + 1;
  });

  return ticketBets;
};

const setRoundsResults = (data) => {
  const results = data.roundsResult;
  const betClass = '-bet';
  each(results, (round) => {
    if (round.result !== 0 && round.result !== -1) {
      if (round.winningBets.length > 6) {
        remove(round.winningBets, (item) => item.name === 'basket');
      }
      if (includes(round.winningBets[1].name, 'Column')) {
        const columnTitle = get(data.rouletteBets[round.winningBets[1].name], 'title');
        set(round.winningBets[1], 'title', columnTitle.substring(0, 3));
      }
      if (round.winningBets[2] && includes(round.winningBets[2].name, 'Dozen')) {
        const dozenTitle = get(data.rouletteBets[round.winningBets[2].name], 'label');
        set(round.winningBets[2], 'title', dozenTitle);
      }
      if (round.winningBets[4]) {
        assign(round, { class: `${round.winningBets[4].name + betClass}` });
      }
    } else {
      if (round.result === -1) {
        set(round, 'result', '00');
      }
      assign(round, { class: 'zero-bet' });
    }
  });

  return results;
};

const setTicketBetsColors = (data) => {
  const results = map(data.betValues, (itemValue) => {
    const betValue = {
      value: itemValue,
      cssClass: 'zero-num',
    };
    if (itemValue > 0) {
      const ifExist = find(data.bets, (item) => item === itemValue);
      betValue.cssClass = ifExist ? 'red-num' : 'black-num';
    }
    return betValue;
  });
  return results;
};

const setBetValues = (data) => {
  const values = data;
  const type = store.getters.wheelType;

  if (type.id === 2 && data.length <= 5) {
    const isNegativeNumber = find(values, (item) => item === -1);
    if (isNegativeNumber) {
      const negativeNumber = findIndex(data, (item) => item === -1);
      values[negativeNumber] = '00';
    }
  }
  return values;
};

const setBetTranslationKey = (name) => {
  let key = `insideBet${upperFirst(name)}Label`;
  if (name === 'high' || name === 'low') {
    key = 'outsideBetHighLowLabel';
  } else if (name === 'even' || name === 'odd') {
    key = 'outsideBetEvenOddLabel';
  } else if (name === 'red' || name === 'black') {
    key = 'outsideBetRedBlackLabel';
  } else if (includes(name, 'Column')) {
    key = 'outsideBetColumnLabel';
  } else if (includes(name, 'Dozen')) {
    key = 'outsideBetDozenLabel';
  }

  return key;
};

const setTicketResultBets = (data) => {
  const mapedBets = map(data.ticketBets, (item) => ({
    id: item.id,
    key: setBetTranslationKey(item.name),
    values: setTicketBetsColors({
      betValues: setBetValues(item.betSelection),
      bets: data.bets,
    }),
    name: upperFirst(item.name),
    event: item.eventId,
    stake: item.stake.toFixed(2),
    status: upperCase(get(item.status, 'name')),
    winnings: item.winnings.toFixed(2),
  }));

  return mapedBets;
};

const parseCheckedTicket = (ticket) => {
  const ticketResult = {
    ticketId: ticket.id,
    stake: ticket.bet,
    taxes: ticket.payinTax,
    totalStake: ticket.payin.toFixed(2),
    jackpotAmount: ticket.superBonus?.amount,
    bets: setTicketResultBets({
      ticketBets: ticket.bets,
      bets: store.getters.wheelBets.red.values,
    }),
    status: get(ticket.status, 'value'),
    payout: ticket.payoutAmountWithoutTax.toFixed(2),
  };

  return ticketResult;
};

const setBetShortcats = (data) => {
  const allShortcuts = [];
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Collator
  const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
  each(valuesIn(data), (bet) => {
    if (bet.shortcut) {
      if (bet.shortcut.name.indexOf('x') > -1) {
        if (bet.shortcut.name === 'Tx') {
          each(bet.shortcut.params, (value, index) => {
            allShortcuts.push(bet.shortcut.name.split('x').join(bet.shortcut.params[index]));
          });
        } else if (bet.shortcut.name === 'xSy') {
          each(bet.values, (value) => {
            if (value[0] === -1) {
              const x = bet.shortcut.name.split('x').join('00');
              allShortcuts.push(x.split('y').join(value[1]));
              const y = bet.shortcut.name.split('x').join(value[1]);
              allShortcuts.push(y.split('y').join('00'));
            } else {
              const x = bet.shortcut.name.split('x').join(value[0]);
              allShortcuts.push(x.split('y').join(value[1]));
              const y = bet.shortcut.name.split('x').join(value[1]);
              allShortcuts.push(y.split('y').join(value[0]));
            }
          });
        } else {
          each(bet.values, (value) => {
            allShortcuts.push(bet.shortcut.name.split('x').join(value[bet.shortcut.paramsValueIndex]));
          });
        }
      } else {
        allShortcuts.push(bet.shortcut.name);
      }
    }
  });
  const shortcuts = allShortcuts.sort(collator.compare);

  return shortcuts;
};

const setPaytable = (data) => {
  let payTableItems = [];
  let insideBetsItems = [];
  let outsideBetsItems = [];
  let outsideBets = [];

  each(filter(data, (item) => item.type === 'outside'), (item) => {
    const bet = {
      name: null,
      odds: Math.round(item.odds),
    };
    if (includes(item.name, 'Column')) {
      const key = `${store.state.outsideBet}Column${upperFirst(store.state.label)}`;
      bet.name = store.getters.translations[key];
    }
    if (includes(item.name, 'Dozen')) {
      const key = `${store.state.outsideBet}Dozen${upperFirst(store.state.label)}`;
      bet.name = store.getters.translations[key];
    }
    if (item.name === 'red' || item.name === 'black') {
      const name = 'RedBlack';
      const key = store.state.outsideBet + name + upperFirst(store.state.label);
      bet.name = store.getters.translations[key];
    }
    if (item.name === 'high' || item.name === 'low') {
      const name = 'HighLow';
      const key = store.state.outsideBet + name + upperFirst(store.state.label);
      bet.name = store.getters.translations[key];
    }
    if (item.name === 'even' || item.name === 'odd') {
      const name = 'EvenOdd';
      const key = store.state.outsideBet + name + upperFirst(store.state.label);
      bet.name = store.getters.translations[key];
    }
    outsideBets = concat(outsideBets, bet);
  });

  outsideBetsItems = uniqBy(outsideBets, (item) => item.name);

  each(filter(data, (item) => item.type === 'inside'), (item) => {
    const bet = {
      name: item.title,
      odds: Math.round(item.odds),
    };
    insideBetsItems = concat(insideBetsItems, bet);
  });

  payTableItems = concat(payTableItems, { insideBets: insideBetsItems });
  payTableItems = concat(payTableItems, { outsideBets: outsideBetsItems });

  return payTableItems;
};

const getPaymentSum = (payload) => {
  if (payload.action === 'clear') {
    return payload.value;
  } if (payload.action === 'context') {
    return store.getters.ticketPayment - sumBy(payload.value, (o) => o.chipValue);
  } if (payload.action === 'undoDouble') {
    return store.getters.ticketPayment / 2;
  } if (payload.action === 'undoChip') {
    return store.getters.ticketPayment - payload.value;
  }
  return payload.value + store.getters.ticketPayment;
};

const parseDefaultPresretBets = (payload) => {
  const { limits } = store.getters;
  const chips = store.getters['roulette/definitionChips'];
  const payment = limits.bet.ticketBetMinPayment === chips[0] || !chips.length
    ? limits.bet.ticketBetMinPayment : chips[0];
  const bets = each(payload, (item) => {
    assign(item, { payment });
  });
  return bets;
};

const setLimits = (payload) => {
  let limits = {};
  const wheelTickets = payload.wheel.definition.tickets;
  if (has(wheelTickets, 'ticketPaymentLimitsPerChannel')) {
    const channel = payload.channel !== 'shop' ? payload.channel : 'retail';
    const channelLimits = wheelTickets.ticketPaymentLimitsPerChannel[toLower(channel)];
    limits = {
      ticket: {
        ticketMinPayment: channelLimits.ticket.min,
        ticketMaxPayment: channelLimits.ticket.max,
      },
      bet: {
        ticketBetMinPayment: channelLimits.bet.min,
        ticketBetMaxPayment: channelLimits.bet.max,
      },
    };
  } else {
    limits = {
      ticket: {
        ticketMinPayment: wheelTickets.ticketMinPayment,
        ticketMaxPayment: wheelTickets.ticketMaxPayment,
      },
      bet: {
        ticketBetMinPayment: wheelTickets.ticketBetMinPayment,
        ticketBetMaxPayment: wheelTickets.ticketBetMaxPayment,
      },
    };
  }
  return limits;
};

export default {
  [types.ACTIVATE_LIGHTNING_INTEGRATION](state) {
    state.isLightningIntegration = true;
  },
  [types.SET_TICKET_BET](state, bet) {
    const { bets } = store.state.ticket;

    if (isArray(bet)) {
      bets.push(...bet);
    } else {
      bets.push(bet);
    }

    assign(store.state.ticket, {
      bets,
      payin: store.state.ticket.payin + bet[0].payment,
      payment: store.state.ticket.payment + bet[0].payment,
      winnings: {
        min: 0,
        max: calculateMaxWinnings(bets),
      },
    });
  },
  [types.CLEAR_TICKET]() {
    store.state.ticket = {
      bets: [],
      payin: store.state.ticket.payin,
      payment: store.state.ticket.payment,
      winnings: {
        min: 0,
        max: 0,
      },
    };
  },
  [types.ADD_TICKET_TO_TICKET_STORE](state, data) {
    if (data) {
      state.ticketStore.push(data);
    }
  },
  [types.SET_AUTH](state, auth) {
    state.auth = auth;
  },
  [types.SET_CONFIG](state, config) {
    assign(state.config, omit(config, ['round', 'translations']));
    state.round = config.round;
  },
  [types.SET_TRANSLATIONS](state, translations) {
    if (!isEmpty(translations)) {
      state.translations = merge({}, state.translations, translations);
    }
  },
  [types.SET_PLATFORM](state, platform) {
    state.platform = platform;
  },
  [types.SET_ROUND](state, round) {
    state.round = round;
  },
  [types.SET_NEXT_ROUNDS](state, nextRounds) {
    state.nextRounds = nextRounds;
  },
  [types.SET_USER](state, payload) {
    state.user = payload.user;
  },
  [types.UPDATE_TICKET](state, ticket) {
    const calculatedTicket = distributeTotalPayment(ticket.bets, ticket.payment, ticket.payin);
    assign(state.ticket, calculatedTicket);
  },
  [types.UPDATE_TICKET_BETS](state, ticket) {
    const updatedBets = updateBets(ticket.bets);
    state.ticket.bets = updatedBets;
  },
  [types.UPDATE_BET](state, status) {
    state.updateBet = status;
  },
  [types.REMOVE_TICKET_FROM_TICKET_STORE](state, ticket) {
    if (ticket) {
      state.ticketStore = remove(state.ticketStore, (item) => item.requestUuid
        === ticket.requestUuid);
    }
  },
  [types.SET_THEME](state, payload) {
    state.theme = payload;
  },
  [types.SET_PALETTE](state, payload) {
    state.palette = payload;
  },
  [types.SET_THIRD_PARTY_TOKEN](state, payload) {
    state.tpToken = payload;
  },
  [types.ACTIVATE_THIRD_PARTY_INTEGRATION](state) {
    state.tpIntegration = true;
  },
  [types.SET_SITE_REFERER](state, payload) {
    state.siteReferer = payload;
  },
  [types.SET_ERROR_MESSAGE](state, status) {
    state.inputErrorMessage = status;
  },
  [types.SET_ROULETTE_RESULTS](state, data) {
    const rouletteResults = setRoundsResults(data);
    state.rouletteResults = rouletteResults;
  },
  [types.SET_CHECKED_TICKET](state, data) {
    const ticketResults = parseCheckedTicket(data);
    state.ticketResult = ticketResults;
  },
  [types.SET_ALLOWED_SHORTCUT](state, data) {
    state.allowedShortcutChars.push(...map(data, toString));
  },
  [types.SET_SHORTCUTS](state, data) {
    const shortcuts = setBetShortcats(data);
    state.betShortcuts = shortcuts;
  },
  [types.SET_BALANCE](state, data) {
    state.balance = data;
  },
  [types.SET_TOTAL_BET_STAKE](state, data) {
    const ticketBet = checkTicketBets(data);
    if (ticketBet) {
      state.totalBetStake = ticketBet.payment;
    }
  },
  [types.SET_PAY_TABLE](state, data) {
    const payTableItems = setPaytable(data);
    state.insideBetsItems = payTableItems[0].insideBets;
    state.outsideBetsItems = payTableItems[1].outsideBets;
  },
  [types.SET_CHANNEL_MOCK](state, payload) {
    state.platform.channel = payload;
  },
  [types.SET_INFO_MESSAGE](state, payload) {
    state.activeMessage = payload;
  },
  [types.SET_LAST_PLAYED_TICKET](state, ticket) {
    state.lastPlayedTicket = ticket;
  },
  [types.SET_LAST_PLAYED_TICKETS](state, payload) {
    state.lastPlayedTicket = head(payload);
    state.lastPlayedTickets = payload;
  },
  [types.SET_NUMBER_OF_PLAYED_TIKCETS](state, payload) {
    state.numberOfPlayedTickets = payload;
  },
  [types.UPDATE_LAST_TICKETS_TOOLTIP_STATUS](state) {
    localStorage.setItem('showLastTicketTooltip', false);
    state.showLastTicketTooltip = false;
  },
  [types.UPDATE_CLEAR_TABLE_TOOLTIP_STATUS](state) {
    localStorage.setItem('showClearTableTooltip', false);
    state.showClearTableTooltip = false;
  },
  [types.SET_PRELOADER](state, value) {
    state.preloader = value;
  },
  [types.SET_PRELOADER_PROGRESS](state, value) {
    state.preloaderProgress = value;
  },
  [types.SET_PRESET](state, payload) {
    if (payload.presets) {
      state.presets = payload.presets;
    }
  },
  [types.SET_PRESETS](state, payload) {
    if (payload) {
      const presets = each(payload, (item) => {
        const preset = item;
        preset.bets = parseDefaultPresretBets(item.bets);
      });
      state.defaultPresets = presets;
    }
  },
  [types.UPDATE_BETSLIP_BET](state, payload) {
    state.ticket = payload;
  },
  [types.CALC_TICKET_MAX_PAYMENT](state, payload) {
    state.ticketPayment = getPaymentSum(payload);
  },
  [types.SET_ROUTE](state, payload) {
    set(state.route, 'name', payload);
  },
  [types.SET_GENERATOR_CODE](state, payload) {
    state.generatorCode = payload;
  },
  [types.SET_BALANCE_VISIBILITY](state, payload) {
    state.showBalance = payload;
  },
  [types.SET_BACK_BUTTON_VISIBILITY](state, payload) {
    state.backButtonEnabled = payload;
  },
  [types.SET_CREATION_TYPE](state, payload) {
    state.creationType = payload;
  },
  [types.SET_LIMITS](state, payload) {
    state.limits = setLimits(payload);
  },
  [types.SET_TICKET_PAYMENT](state) {
    state.ticket.payment = sumBy(state.ticket.bets, (item) => toNumber(item.payment));
    state.ticket.payin = state.ticket.payment;
  },
  [types.UPDATE_BET_PAYMENT](state, payload) {
    const bet = checkTicketBets(payload.ticketBet);
    if (bet) set(bet, 'payment', payload.totalStake);
  },
  [types.SET_DEFAULT_CHIP](state, chip) {
    state.defaultChip = chip;
  },
  [types.REMOVE_TICKET_BET](state, bet) {
    const ticketBet = checkTicketBets(bet);
    if (ticketBet) {
      store.state.ticket.bets = remove(store.state.ticket.bets, (item) => item !== ticketBet);
    }
  },
  [types.SET_REMOVE_BUTTON_STATUS](state, status) {
    state.isRemoveButtonActive = status;
  },
};
