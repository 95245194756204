import moment from 'moment';

export default {
  install: function install(Vue) {
    Vue.component('seconds-countdown', {
      name: 'SecondsCountdown',
      props: [
        'start',
        'duration',
      ],
      data() {
        return {
          currentTime: moment.utc(),
          interval: null,
        };
      },
      computed: {
        startTime() {
          return moment.utc(this.start);
        },
        value() {
          if (this.currentTime && this.startTime) {
            const startTime = this.startTime.clone();
            return startTime
              .add(this.duration, 's')
              .diff(this.currentTime, 's');
          }

          return null;
        },
      },
      beforeMount() {
        this.interval = setInterval(() => {
          this.currentTime = moment.utc();
        }, 1000);
      },
      beforeDestroy() {
        if (this.interval) {
          clearInterval(this.interval);
        }
      },
      render(createElement) {
        const time = this.value < 0 ? 0 : this.value;
        return createElement(
          'span',
          moment.utc(time * 1000).format('m:ss'),
        );
      },
    });
  },
};
