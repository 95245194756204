import { template, findIndex } from 'lodash';
import { bus, ticketChecker } from '@/utility';
import store from '@/store';

const setListener = (pusher, channelName, channelId) => {
  if (!channelId) return; // disable Pusher subscription if there is no player or device
  const channel = pusher.subscribe(channelName);

  channel.bind('TicketUpdate', (data) => {
    bus.$emit('ticketUpdate', data);
    if (data?.action === 'add') {
      if (store.getters.directPayin) { // check currently used only on web and mobile
        bus.$emit('ticketIsResolved', true);
        bus.$emit('refreshTicketList');
        const ticketIndex = findIndex(store.getters.ticketStore, ['requestUuid', data.requestUuid]);
        if (ticketIndex > -1) {
          store.dispatch('removeTicketFromTicketStore', data);
          ticketChecker.clearChecker();
        } else {
          store.dispatch('addTicketToTicketStore', data);
        }
      }
      if (!store.getters.isTerminal) {
        store.dispatch('setInfoMessage', {
          message: template(store.getters.translations.rouletteInfoBarConfirmedTicket)(data),
          messageType: 'success-style',
          icon: 'n-i-check',
          closeIconActive: false,
          delay: 2000,
        });
      }
    }
  });
};

export default {
  pusher: null,
  init() {
    const {
      channel,
      user,
      platform,
      config,
    } = store.getters;
    const isPlayerChannel = channel === 'web' || channel === 'mobile';
    const channelSuffix = isPlayerChannel ? 'Player' : 'Device';
    const channelId = isPlayerChannel ? user.uuid : platform.deviceUuid;
    const productDisplayId = config.gateway.slaveId;
    const channelName = `NSoft.Seven.${productDisplayId}.${channelSuffix}.${channelId}`;

    if (!this.pusher) {
      this.pusher = new Pusher(`${process.env.VUE_APP_PUSHER_KEY}`, {
        cluster: 'eu',
        forceTLS: true,
      });
    }
    if (!this.pusher.channel(channelName)) {
      setListener(this.pusher, channelName, channelId);
    }
  },
};
