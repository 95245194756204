import http from './http';

export default {
  getCode({ wheelIdentifier, ticket }) {
    return http.put(`/tickets/code/?wheelAccountId=${wheelIdentifier}`, { ticket }).then((response) => {
      const result = response.data;
      return result;
    });
  },
  getTicketByCode({ code, wheelIdentifier }) {
    return http.get(`/tickets/code/${code}?wheelAccountId=${wheelIdentifier}`).then((response) => {
      const result = response.data;
      return result;
    });
  },
};
