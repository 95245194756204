import { find } from 'lodash';

// this will be moved to games-config-js in local tenant configuration
const tenants = [
  {
    name: 'balkanbet',
    env: 'production',
    companyUuid: '4f54c6aa-82a9-475d-bf0e-dc02ded89225',
    endpoints: {
      payin: 'https://services-rs.7platform.com',
    },
  },
  {
    name: 'stanleybet',
    env: 'production',
    companyUuid: '1136f22d-84c7-4686-8836-af313f1018ee',
    endpoints: {
      payin: 'https://services-ro2.7platform.com',
    },
  },
  {
    name: 'balkanbet',
    env: 'staging',
    companyUuid: '5847f0c1-2b87-4aab-a83e-01b9185b769b',
    endpoints: {
      payin: 'https://services-staging.7platform.com',
    },
  },
  {
    name: 'excelbet',
    env: 'production',
    companyUuid: 'b0e9462c-2a9b-44f8-aaaf-299c33c66d32',
    endpoints: {
      payin: 'https://services-ro.7platform.com',
    },
  },
  {
    name: 'excelbet',
    env: 'staging',
    companyUuid: 'da6fbdb5-d479-4605-806c-43c11f0d20cb',
    endpoints: {
      payin: 'https://services-staging.7platform.com',
    },
  },
];

export default {
  getTenantSettings(companyUuid) {
    return find(tenants, (tenant) => tenant.companyUuid === companyUuid);
  },
};
