import http from './http';

export default {
  getPresets({ player, wheelIdentifier }) {
    return http.get(`/players/${player}/${wheelIdentifier}/presets`, {
    }).then((response) => {
      const result = response.data;
      return result;
    });
  },
  setPreset({ player, presets, wheelIdentifier }) {
    return http.put(`/players/${player}/${wheelIdentifier}/presets`, { presets }).then((response) => {
      const result = response.data;
      return result;
    });
  },
  deletePreset({ player, preset }) {
    return http.delete(`/players/${player}/presets`, { data: { preset } }).then((response) => {
      const result = response.data;
      return result;
    });
  },
};
