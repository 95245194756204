import http from './http';

export default {
  getConfig({ wheelIdentifier, locale, app }) {
    return http.get(`/config/${wheelIdentifier}?locale=${locale}&app=${app}`, {
    }).then((response) => {
      const result = response.data;
      return result;
    });
  },
};
