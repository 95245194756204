import Vue from 'vue';
import Vuetify from 'vuetify';
import { sync } from 'vuex-router-sync';
import LocalizedLabel from '@/components/LocalizedLabel';
import SecondsCountdown from '@/components/SecondsCountdown';
import { gatewayApi } from '@/api';
import { gatewayMock } from '@/mocks';
import { webViewIntegration, errorTracker, lightningIntegration } from '@/utility';
import App from './App';
import router from './router';
import store from './store';
import '../node_modules/vuetify/dist/vuetify.min.css';

const qp = new URLSearchParams(window.location.search);
const integrationType = qp.get('integrationType') || 'lightning';

Vue.config.productionTip = false;
Vue.use(LocalizedLabel);
Vue.use(SecondsCountdown);

Vue.use(Vuetify);
sync(store, router);

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

if (process.env.NODE_ENV === 'local') {
  gatewayMock.initMasterMock();
}
if (process.env.VUE_APP_ERROR_TRACKER_ACTIVE === 'true') {
  const sentryDsn = 'https://cfdb9f0cf2434fdb8b7290a4d4346afc@o73276.ingest.sentry.io/6037509';
  errorTracker.start(Vue, sentryDsn, process.env.NODE_ENV);
}

if (integrationType === 'lightning' && !webViewIntegration.isActive()) {
  store.dispatch('activateLightningIntegration');
  lightningIntegration.init();
} else {
  if (webViewIntegration.isActive()) {
    webViewIntegration.init();
  } else {
    gatewayApi.init();
  }
  gatewayApi.setListeners();
}
