import {
  assign,
  cloneDeep,
  each,
  has,
  isArray,
  isEmpty,
  last,
  sumBy,
} from 'lodash';
import { socket } from '@/plugins';
import { formatter } from '@/utility';
import {
  codeGeneratorApi,
  configApi,
  gatewayApi,
  presetsApi,
  auth,
  ticketListApi,
} from '../api';
import { configApiMock } from '../mocks';
import types from './mutationTypes';

let messageTimeout = null;

const isValidTicketPayment = (ticket) => {
  const paymentAmountRounded = formatter.roundNumber(ticket.payment);
  const sumOfPaymentsPerBet = formatter.roundNumber(sumBy(ticket.bets,
    (bet) => bet.payment));
  const paymentAmountDifference = formatter.roundNumber(paymentAmountRounded - sumOfPaymentsPerBet);

  return {
    isValid: Math.abs(paymentAmountRounded) === Math.abs(sumOfPaymentsPerBet),
    paymentAmountDifference,
  };
};

const recalculateLastTicketBetPayment = (paymentAmountDifference, ticket) => {
  const lastBet = last(ticket.bets);
  let betPayment = lastBet.payment;
  if (paymentAmountDifference) {
    betPayment += paymentAmountDifference;
    const betPaymentFormatted = betPayment.toFixed(2);
    lastBet.payment = formatter.roundNumber(betPaymentFormatted);
  }
};

const setInfoMessage = (commit, payload) => {
  messageTimeout = setTimeout(() => {
    commit(types.SET_INFO_MESSAGE, payload);
  }, payload.delay);
};

export default {
  activateTPIntegration({ commit }) {
    commit(types.ACTIVATE_THIRD_PARTY_INTEGRATION);
  },
  activateLightningIntegration({ commit }) {
    commit(types.ACTIVATE_LIGHTNING_INTEGRATION);
  },
  addTicketToTicketStore({ commit }, data) {
    commit(types.ADD_TICKET_TO_TICKET_STORE, data);
  },
  calcTicketMaxPayment({ commit }, payload) {
    commit(types.CALC_TICKET_MAX_PAYMENT, payload);
  },
  clearUserPresets({ commit }) {
    commit(types.SET_PRESET, { presets: [] });
  },
  clearTicket({ commit }) {
    commit(types.CLEAR_TICKET);
    commit(types.SET_TICKET_PAYMENT);
  },
  deletePreset({ commit, getters }, payload) {
    if (getters.user.id) {
      return presetsApi.deletePreset({
        player: getters.user.uuid,
        preset: payload,
      }).then((result) => {
        commit(types.SET_PRESET, result);
        return result;
      });
    }
    return false;
  },
  generateCode({ commit, getters }) {
    const ticket = cloneDeep(getters.ticket);
    ticket.payment = getters.totalPayment;
    ticket.payin = getters.totalPayment;
    return codeGeneratorApi.getCode({
      wheelIdentifier: getters.config.wheel.wheelAccountId,
      ticket,
    }).then((result) => {
      commit(types.SET_GENERATOR_CODE, result.code);
      return result;
    });
  },
  async getPlayer({ dispatch }, { config, token, tpIntegration }) {
    try {
      const player = await auth.authenticate(config, token);
      const isLoggedIn = !!player.uuid;
      if (isLoggedIn) {
        dispatch('setUser', {
          user: {
            id: player.uuid,
            uuid: player.uuid,
            firstName: player.firstName,
            lastName: player.lastName,
            name: player.username,
            logged: isLoggedIn,
            timezone: player.timezone,
            auth: player.auth,
          },
        });
        dispatch('setBalance', player.balance);
        if (tpIntegration) {
          dispatch('activateTPIntegration');
          dispatch('setThirdPartyToken', player.auth.thirdPartyToken);
        }
      }
    } catch (error) {
      dispatch('setError', {
        name: 'InvalidCredentialsError',
      });
    }
  },
  getTicketByWebCode({ getters }, payload) {
    return codeGeneratorApi.getTicketByCode({
      code: payload,
      wheelIdentifier: getters.config.wheel.wheelAccountId,
    }).then((result) => {
      const response = result;
      if (!isEmpty(result.payload)) {
        response.payload = JSON.parse(result.payload);
        response.ticket = response.payload.ticket;
        gatewayApi.onTicketRebet({ data: response });
      }
      return result;
    });
  },
  getUserPresets({ commit, getters }, payload) {
    return presetsApi.getPresets({
      player: payload,
      wheelIdentifier: getters.config.wheel.wheelAccountId,
    }).then((result) => {
      commit(types.SET_PRESET, result);
      return result;
    });
  },
  loadConfig({ commit, getters, dispatch }, payload) {
    // addCommonPayload(getters.config, payload); - possibly redundant
    commit(types.SET_PRELOADER_PROGRESS, 50);
    if (process.env.NODE_ENV === 'local') {
      // platformless
      commit(types.SET_CHANNEL_MOCK, payload);
      return configApiMock.getConfig().then((result) => {
        commit(types.SET_CONFIG, result);
        dispatch('setShortcuts');
        dispatch('setPaytable');
        return getters.config;
      }).then((config) => {
        socket.init(config);
      });
    }
    return configApi.getConfig(payload).then((result) => {
      dispatch('initializeConfiguration', result);
      return getters.config;
    }).then((config) => {
      socket.init(config);
    });
  },
  loadPlayedTickets({ commit, getters }, params) {
    return ticketListApi.getList({
      wheel: getters.config.wheel.id,
      wheelIdentifier: getters.config.wheel.wheelAccountId,
      player: getters.user.uuid || getters.user.id,
      locale: getters.config.locale,
      params,
    }).then((response) => {
      const { tickets } = response;
      commit(types.SET_LAST_PLAYED_TICKETS, tickets);
      commit(types.SET_NUMBER_OF_PLAYED_TIKCETS, tickets.length);

      return tickets;
    });
  },
  initializeConfiguration({ commit, dispatch }, config) {
    commit(types.SET_PRELOADER_PROGRESS, 100);
    commit(types.SET_PRELOADER, false);
    commit(types.SET_CONFIG, config);
    commit(types.SET_TRANSLATIONS, config.translations);
    dispatch('setShortcuts');
    dispatch('setPaytable');
  },
  removeTicketBet({ commit }, payload) {
    commit(types.REMOVE_TICKET_BET, payload);
    commit(types.SET_TICKET_PAYMENT);
  },
  removeTicketFromTicketStore({ commit }, data) {
    commit(types.REMOVE_TICKET_FROM_TICKET_STORE, data);
  },
  setAllowedShortcut({ commit }, data) {
    commit(types.SET_ALLOWED_SHORTCUT, data);
  },
  setAuth({ commit }, payload) {
    commit(types.SET_AUTH, payload);
  },
  setBackButtonVisibility({ commit }, payload) {
    commit(types.SET_BACK_BUTTON_VISIBILITY, payload);
  },
  setBalance({ commit }, data) {
    commit(types.SET_BALANCE, data);
  },
  setBalanceVisibility({ commit }, payload) {
    commit(types.SET_BALANCE_VISIBILITY, payload);
  },
  setCheckedTicket({ commit }, ticket) {
    commit(types.SET_CHECKED_TICKET, ticket);
  },
  setCreationType({ commit }, payload) {
    commit(types.SET_CREATION_TYPE, payload);
  },
  setDefaultChip({ commit }, chip) {
    commit(types.SET_DEFAULT_CHIP, chip);
  },
  setErrorMessage({ commit }, data) {
    commit(types.SET_ERROR_MESSAGE, data);
  },
  setInfoMessage({ commit, getters }, payload) {
    if (messageTimeout) {
      clearTimeout(messageTimeout);
    }
    commit(types.SET_INFO_MESSAGE, payload);
    if (has(payload, 'delay')) {
      setInfoMessage(commit, {
        message: getters.translations.infoBarPlaceChip,
        messageType: 'info-style',
        icon: 'n-i-info',
        closeIconActive: true,
        delay: payload.delay,
      });
    }
  },
  setLastPlayedTicket({ commit }, ticket) {
    commit(types.SET_LAST_PLAYED_TICKET, ticket);
  },
  setLimits({ commit, getters }) {
    const { channel, wheel } = getters;
    commit(types.SET_LIMITS, { channel, wheel });
  },
  setPalette({ commit }, payload) {
    commit(types.SET_PALETTE, payload);
  },
  setPaytable({ getters, commit }) {
    const { wheelBets } = getters;
    commit(types.SET_PAY_TABLE, wheelBets);
  },
  setPlatform({ commit }, payload) {
    commit(types.SET_PLATFORM, payload);
  },
  setPreset({ commit, getters }, payload) {
    if (getters.user.id) {
      return presetsApi.setPreset({
        player: getters.user.uuid,
        presets: payload,
        wheelIdentifier: getters.config.wheel.wheelAccountId,
      }).then((result) => {
        commit(types.SET_PRESET, result);
        return result;
      });
    }
    return false;
  },
  setPresets({ commit, getters }) {
    if (getters.wheelPresets) {
      commit(types.SET_PRESETS, getters.wheelPresets);
    }
  },
  setRemoveButtonStatus({ commit }, status) {
    commit(types.SET_REMOVE_BUTTON_STATUS, status);
  },
  setRouletteResults({ commit }, data) {
    commit(types.SET_ROULETTE_RESULTS, data);
  },
  setRoute({ commit }, payload) {
    commit(types.SET_ROUTE, payload);
  },
  setShopRebetTicket({ dispatch, getters }, data) {
    const { round, nextRounds } = getters;
    each(data, (item) => {
      const bet = item;
      const event = nextRounds.length ? nextRounds[0].displayId : round.displayId;
      assign(bet, { event });
      dispatch('setTicketBet', { bet });
    });
  },
  setShortcuts({ getters, commit }) {
    const { wheelBets } = getters;
    commit(types.SET_SHORTCUTS, wheelBets);
  },
  setSiteReferer({ commit }, payload) {
    commit(types.SET_SITE_REFERER, payload);
  },
  setTheme({ commit }, payload) {
    commit(types.SET_THEME, payload);
  },
  setThirdPartyToken({ commit }, payload) {
    commit(types.SET_THIRD_PARTY_TOKEN, payload);
  },
  setTicketBet({ commit, getters }, payload) {
    const bets = isArray(payload.bet) ? payload.bet : [payload.bet];
    const bet = getters.parseTicketBets(bets);
    commit(types.SET_TICKET_BET, bet);
  },
  setTicketPayment({ commit }) {
    commit(types.SET_TICKET_PAYMENT);
  },
  setTotalBetStake({ commit }, data) {
    commit(types.SET_TOTAL_BET_STAKE, data);
  },
  setUser({ commit }, payload) {
    commit(types.SET_USER, payload);
  },
  updateBet({ commit }, payload) {
    commit(types.UPDATE_BET, payload);
  },
  updateBetslipTicket({ commit }, { ticket, recalculateBetPayment }) {
    const validatedPaymentAmount = isValidTicketPayment(ticket);
    if (!validatedPaymentAmount.isValid && recalculateBetPayment) {
      recalculateLastTicketBetPayment(validatedPaymentAmount.paymentAmountDifference, ticket);
    }
    commit(types.UPDATE_BETSLIP_BET, ticket);
  },
  updateClearTableTooltipStatus({ commit }) {
    commit(types.UPDATE_CLEAR_TABLE_TOOLTIP_STATUS);
  },
  updateGeneratorCode({ commit }) {
    commit(types.SET_GENERATOR_CODE, null);
    commit(types.CLEAR_TICKET);
  },
  updateLastTicketsTooltipStatus({ commit }) {
    commit(types.UPDATE_LAST_TICKETS_TOOLTIP_STATUS);
  },
  updateTicket({ commit }, payload) {
    commit(types.UPDATE_TICKET, payload);
  },
  updateBetPayment({ commit }, payload) {
    commit(types.UPDATE_BET_PAYMENT, payload);
    commit(types.SET_TICKET_PAYMENT);
  },
};
