import { localStorage } from '@/utility';
import types from '../mutationTypes';

export default function persistAuthPlugin(store) {
  store.subscribe((mutation, state) => {
    if (types.SET_USER === mutation.type && state.user) {
      localStorage.setItem('auth', state.user.auth.token);
    }
  });
}
