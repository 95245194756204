import { template } from 'lodash';
import { bus } from '@/utility';
import { ticketApi } from '@/api';
import store from '../store';

let checker = null;

const clearInterval = () => {
  window.clearInterval(checker);
  checker = null;
};

const checkTicket = (config, reqUuid) => {
  let attempts = 0;
  checker = window.setInterval(() => {
    const headers = store.getters.getHeaders;
    attempts += 1;
    ticketApi.get(`/web/tickets/request/${reqUuid}/product/Roulette/check.json`, {
      headers,
    }).then((response) => {
      const result = response.data;
      bus.$emit('ticketUpdate', result);
      bus.$emit('ticketIsResolved', true);
      clearInterval();
      store.dispatch('setInfoMessage', {
        message: template(store.getters.translations.rouletteInfoBarConfirmedTicket)(result),
        messageType: 'success-style',
        icon: 'n-i-check',
        closeIconActive: false,
        delay: 2000,
      });
    }).catch((error) => {
      bus.$emit('ticketIsResolved', false);
      clearInterval();
      store.dispatch('setInfoMessage', {
        message: error.message,
        messageType: 'error-style',
        icon: 'n-i-void',
        closeIconActive: false,
      });
    });
    if (attempts === config.repetitions) {
      clearInterval();
    }
  }, config.delay);
};

export default {
  startChecker(reqUuid) {
    checkTicket({
      delay: 10000,
      repetitions: 3,
    }, reqUuid);
  },
  clearChecker() {
    clearInterval();
  },
};
