export default {
  auth: {},
  authStrategies: ['token', 'tempToken'],
  betslip: {
    tickets: [
      {
        id: 'single',
        active: true,
        betsCollection: 'Roulette',
        betTemplate: 'generic',
        group: {
          id: 'Roulette',
          taxesConfig: {
            payin: {
              value: false,
              hideTax: false,
            },
            payout: {
              value: false,
              hideTax: false,
            },
          },
        },
        ticketGroup: 'Roulette',
        ticketType: {
          id: 3,
          name: 'single',
        },
        setStakeStrategy: 'sevenVirtualSetStake',
        setFormatPayin: 'sevenVirtualFormataPayin',
        autoStakeSetting: true,
        winningStrategy: false,
      },
    ],
  },
  config: {
    app: '',
    api: process.env.VUE_APP_API_URL,
    gateway: {
      masterOrigins: process.env.VUE_APP_GATEWAY_ORIGINS,
      slaveId: 'Roulette',
      settings: {
        shop: {
          ticketConfig: {
            availableTicketActions: {
              closed: [
                { action: 'rebet', conditions: [] },
              ],
              open: [
                { action: 'cancel', conditions: [] },
                { action: 'printCopy', conditions: [] },
                { action: 'rebet', conditions: [] },
              ],
              lost: [
                { action: 'rebet', conditions: [] },
              ],
              payedout: [
                { action: 'reprintConfirmation', conditions: [] },
                { action: 'rebet', conditions: [] },
              ],
              pending: [
                { action: 'cancel', conditions: [] },
                { action: 'printCopy', conditions: [] },
                { action: 'rebet', conditions: [] },
              ],
              rejected: [
                { action: 'rebet', conditions: [] },
              ],
              in_play: [
                { action: 'rebet', conditions: [] },
              ],
              won: [
                { action: 'payout', conditions: [] },
                { action: 'rebet', conditions: [] },
              ],
              won_overdue: [
                { action: 'payout', conditions: [] },
                { action: 'rebet', conditions: [] },
              ],
            },
            color: '#1b8a93',
            codePrefix: '4-',
            doTicketStatusCheck: true,
            pendingTicketLimitTotal: 100,
            pendingTicketLimitByGame: 1,
            pendingTicketCheckInterval: 15000,
            pendingTicketRejectPeriod: 60000,
            sentTicketRejectPeriod: 10000,
            useTicketLocalStorage: true,
            validateTicket: false,
          },
          shortcuts: {},
          icon: 'n-i-roulette',
        },
      },
      keyListeners: '*',
      keyPropagation: [
        '111',
        '112',
        '113',
        '114',
        '115',
        '187',
        '16',
        '27',
        '32',
        '9',
      ],
    },
    locale: 'en',
    wheel: {},
  },
  platform: {},
  round: {},
  updateBet: false,
  nextRounds: [],
  ticket: {
    bets: [
      /*
        betName: string,
        betSelection: array,
        displayBetName: string,
        displayName: string,
        displayValue: string,
        numberOfRounds: number,
        payment: number,
        round: number,
        payment: number,
      */
    ],
    payment: 0,
    payin: 0,
    winnings: {
      min: 0,
      max: 0,
    },
  },
  ticketStatuses: {
    positive: ['won', 'paid_out'],
    negative: ['lost', 'closed'],
    neutral: ['open', 'pending'],
  },
  lastPlayedTicket: {},
  lastPlayedTickets: {},
  limits: {},
  numberOfPlayedTickets: 0,
  allowedShortcutChars: [
    ',',
    'B',
    'C',
    'D',
    'E',
    'H',
    'L',
    'O',
    'R',
    'S',
    'X',
    'Y',
    'T',
  ],
  betShortcuts: [],
  activeMessage: {},
  ticketPayment: null,
  /* Roulette */
  totalBetStake: '',
  theme: 'dark',
  palette: 'blue',
  balance: null,
  insideBetsItems: [],
  outsideBetsItems: [],
  rouletteResults: [],
  ticketResult: [],
  inputErrorMessage: null,
  line: 'line',
  row: 'row',
  top: 'top',
  center: 'center',
  bottom: 'bottom',
  special: 'specials',
  column: 'column',
  label: 'label',
  outsideBet: 'outsideBet',
  insideBet: 'insideBet',
  redTableColor: 'red-color',
  blackTableColor: 'black-color',
  columnSpot: 'column-spot',
  outsideSpot: 'outside-spot',
  clearRowDozen: 'clear-row-dozen',
  clearRowSpecial: 'clear-row-special',
  socketProvider: 'pusher',
  showLastTicketTooltip: true,
  showClearTableTooltip: true,
  tpIntegration: false,
  tpToken: null,
  siteReferer: null,
  ticketStore: [],
  preloader: true,
  preloaderProgress: 0,
  route: null,
  defaultPresets: [],
  presets: [],
  generatorCode: null,
  showBalance: false,
  backButtonEnabled: false,
  creationType: 'default',
  moneyFormat: '0,0.00',
  defaultChip: 0,
  isRemoveButtonActive: false,
  isLightningIntegration: false,
  /* eslint-disable no-template-curly-in-string */
  translations: {
    betIdLabel: 'Bet ID',
    invalidBetShortcutError: 'Invalid bet shortcut. Please check.',
    invalidBetPayment: 'Invalid bet payment. Minimum is ${ticketBetMinPayment}, maximum is ${ticketBetMaxPayment}.',
    invalidTicketPayment: 'Invalid ticket payment. Minimum is ${ticketMinPayment}, maximum is ${ticketMaxPayment}.',
    invalidBetCount: 'Invalid number of bets. Maximum is ${maxBets}.',
    invalidBalance: 'You don\'t have enough money in your balance.',
    roundLabel: 'Round',
    roundInProgressLabel: 'in progress',
    roundStartingInLabel: 'starting in',
    bettingDisabled: 'Betting disabled',
    betOdds: 'Odds',
    results: 'Results',
    rouletteTableTabLabel: 'Table',
    rouletteWheelTabLabel: 'Wheel',
    rouletteDoubleButtonLabel: 'Double',
    rouletteRebetButtonLabel: 'Rebet',
    rouletteUndoButtonLabel: 'Undo',
    rouletteCloseButtonLabel: 'Close',
    rouletteFutureRoundsButtonLabel: 'Future rounds',
    rouletteTotalStakeButtonLabel: 'Total stake',
    roulettePayInButtonLabel: 'Pay in',
    rouletteFirstDozenBetLabel: '1st 12',
    rouletteSecondDozenBetLabel: '2nd 12',
    rouletteThirdDozenBetLabel: '3rd 12',
    rouletteLowBetLabel: 'LOW',
    rouletteEvenBetLabel: 'EVEN',
    rouletteRedBetLabel: 'RED',
    rouletteBlackBetLabel: 'BLACK',
    rouletteOddBetLabel: 'ODD',
    rouletteHighBetLabel: 'HIGH',
    rouletteFirstColumnBetLabel: '1st col.',
    rouletteSecondColumnBetLabel: '2nd col.',
    rouletteThirdColumnBetLabel: '3rd col.',
    rouletteFirstDozenTableBetLabel: '1st 12',
    rouletteSecondDozenTableBetLabel: '2nd 12',
    rouletteThirdDozenTableBetLabel: '3rd 12',
    rouletteLowTableBetLabel: 'LOW',
    rouletteEvenTableBetLabel: 'EVEN',
    rouletteRedTableBetLabel: 'RED',
    rouletteBlackTableBetLabel: 'BLACK',
    rouletteOddTableBetLabel: 'ODD',
    rouletteHighTableBetLabel: 'HIGH',
    rouletteFirstColumnTableBetLabel: '1st col.',
    rouletteSecondColumnTableBetLabel: '2nd col.',
    rouletteThirdColumnTableBetLabel: '3rd col.',
    infoBarPlaceChip: 'Place chip on desired bet to start.',
    rouletteInfoBetsSelected: 'bets selected',
    rouletteInfoBet: 'bet',
    rouletteInfoBarTicketProcess: 'Ticket is processing',
    payTableLabel: 'PAYTABLE',
    payTableDialogLabel: 'PAY TABLE',
    payTableText: 'Play selected bets in advance for selected numbers of rounds',
    futureRoundsLabel: 'FUTURE ROUNDS',
    futureRoundsText: 'Play selected bets in advance for selected numbers of rounds',
    insideBetsTitleLabel: 'Inside bets',
    insideBetStraightLabel: 'Stright Up',
    insideBetSplitLabel: 'Split',
    insideBetCornerLabel: 'Corner',
    insideBetStreetLabel: 'Street',
    insideBetSixlineLabel: 'Double street',
    insideBetTrioLabel: 'Trio',
    insideBetBasketLabel: 'Basket',
    outsideBetsTitleLabel: 'Outside bets',
    outsideBetDozenLabel: 'Dozen',
    outsideBetColumnLabel: 'Column',
    outsideBetHighLowLabel: 'High/Low',
    outsideBetEvenOddLabel: 'Even/Odd',
    outsideBetRedBlackLabel: 'Red/Black',
    wheelZeroGameLabel: 'ZERO GAME',
    wheelNeighboursOfZeroLabel: 'NEIGHBOURS OF ZERO',
    wheelOrphansLabel: 'ORPHANS',
    wheelThirdOfTheWheelLabel: 'THIRD OF THE WHEEL',
    resultLastRoundsLabel: 'Last 10 rounds',
    ticketCheckStatusLabel: 'Status',
    ticketCheckPayoutLabel: 'Payout',
    ticketCheckTicketLabel: 'Ticket ID',
    ticketCheckStakeLabel: 'Stake',
    ticketCheckTaxesLabel: 'Taxes',
    ticketCheckBetLabel: 'Bet',
    ticketCheckTypeLabel: 'Type',
    ticketCheckEventLabel: 'Event',
    ticketCheckWiningsLabel: 'Winnings',
    ticketSystemTicketLabel: 'SYSTEM TICKET',
    totalPaymentLabel: 'Total payment',
    totalPayoutLabel: 'Total payout',
    oddLabel: 'Odd',
    paymentLabel: 'Payment',
    payinLimits: 'Payin limits',
    maxPerBet: 'Max per bet',
    minPerBet: 'Min per bet',
    paymentTaxLabel: 'Payment tax',
    payoutTaxLabel: 'Payout tax',
    ticketTimeLabel: 'Time',
    ticketCodeLabel: 'Ticket code',
    ticketStatusLabel: 'Status',
    ticketWinLabel: 'Win',
    portraitModeSwitchMessage: 'Please switch back to potrait mode to play the game',
    ticketHistoryHeaderLabel: 'Last tickets',
    rouletteProductTitle: 'Roulette',
    possiblePayoutLabel: 'Possible payout',
    rouletteInfoBarConfirmedTicket: 'Ticket confirmed: ${id}',
    signInLabel: 'Sign in',
    lastTicketHelpTooltipLabel: 'Track your tickets here',
    clearTooltipLabel: 'Here you can clear the table',
    betslipLabel: 'Betslip',
    eventLabel: 'Event',
    presetsLabel: 'Presets',
    presetsAddLabel: 'add current selection',
    ticketIssuedLabel: 'Issued',
    presetsMaxNumber: 'You created max number of presets',
    presetNameLabel: 'Preset name',
    resultLabel: 'result',
    generateCodeLabel: 'Generate code',
    ticketCodeReadyLabel: 'Ticket is ready',
    ticketCodeOperatorLabel: 'Show this code to operator',
    okLabel: 'OK',
    balanceLabel: 'Balance',
    ticketStatusClosed: 'Closed',
    ticketStatusOpen: 'Open',
    ticketStatusLost: 'Lost',
    ticketStatusWon: 'Won',
    ticketStatusPending: 'Pending',
    ticketStatusRejected: 'Rejected',
    ticketStatusPaidOut: 'Paidout',
    ticketStatusInPlay: 'In play',
    ticketStatusWonOverdue: 'Won overdue',
    ticketStatusWonExpired: 'Won expired',
    ticketStatusExpired: 'Expired',
    ticketHistoryDateTime: 'Date and time',
    ticketHistoryPrintCopy: 'Print Copy',
    ticketHistoryToTop: 'To top',
    stakeLabel: 'Stake',
    payInLabel: 'Pay in',
    roulettePayinLabel: 'Pay in',
    roulettePayoutLabel: 'Payout',
    jackpotLabel: 'Jackpot',
    focusFirstBet: 'Focus first bet',
    perBetLabel: 'Per bet',
    focusBetInput: 'Focus bet input',
    clearBetslip: 'Clear betslip',
  },
  ui: {},
  user: {},
};
