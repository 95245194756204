import Vue from 'vue';
import Vuex from 'vuex';
import { rouletteSdkModule } from '@nsftx/casino-client-sdk/src/store/modules';
// eslint-disable-next-line import/no-cycle
import storeConfig from './storeConfig';

Vue.use(Vuex);

const store = new Vuex.Store(storeConfig);
store.registerModule('roulette', rouletteSdkModule);

export default store;
